import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

import DBM from "../../../../assets/Home/Work/DBM.png";
import PeopleClerk from "../../../../assets/Home/Work/PeopleClerk.webp";
import SM from "../../../../assets/Home/Work/SM.png";

const WorkExperience = () => {
  return (
    <div className="main-page-wrapper">
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="border-block col-xxl-9 col-xl-10 m-auto">
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading">June 2022- August 2022</h6>
              <h3 className="title font-recoleta">Momentive (Survey Monkey) </h3>
              <h6 className="sub-heading">Mobile Software Engineer</h6>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={SM}
                    thumbnail={SM}
                    width="960"
                    height="300"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={SM}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <p>
              Worked on the Survey Monkey Rewards app integrating the Prizeout SDK within the app through an integrated 
              web view, giving users hundreds more options to cash out their wallet balance.
              </p>
              <p>
              Modified the backend portion of the app using Python to fully support the Prizeout SDK.
              </p>
              <p>
              Advanced my skills in reactive programming, Swift, Xcode, and iOS development, bridging these skills with RxSwift.
              </p>
            </div>
            <div className="border-block col-xxl-9 col-xl-10 m-auto">
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading">May 2021 – May 2022</h6>
              <h3 className="title font-recoleta">People Clerk</h3>
              <h6 className="sub-heading">Web Developer</h6>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={PeopleClerk}
                    thumbnail={PeopleClerk}
                    width="960"
                    height="300"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={PeopleClerk}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <p>
              Created and integrated robust software in Reactjs to render over 350 different articles and 
              assets from Contentful through their GraphQL retrieval API while preserving backward compatibility 
              with the antiquated system through the use of slugs.
              </p>
              <p>
              Simplified the client intake process with new form functionalities such as address autocompletion using 
              Google Maps and Places APIs integrated with Formik.
              </p>
              <p>
              Other duties included bug detection, fixing and system testing support with Jest. Design, build, integration,
              delivery, and post-launch support of different components and applications delivered to Google’s Firebase platform.
              </p>
            </div>
            <div className="col-xxl-9 col-xl-10 m-auto">
              
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading mt-100">May 2018 - August 2018</h6>
              <h3 className="title font-recoleta">Diaz Briscoe Medina, PA</h3>
              <h6 className="sub-heading">Python Developer</h6>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={DBM}
                    thumbnail={DBM}
                    width="960"
                    height="300"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={DBM}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <p>
              Designed and developed a photo manipulation and OCR software in Python using Tesseract and OpenCV designed
              to receive scanned PDF court documents and automatically convert them to a word document. The program
              resulted in 10x more efficient transferring of court documents and aided in meeting court deadlines.
              </p>
              <p>
              Created a PDF parsing program featuring a GUI using PyQt meant to parse several thousand-page long evidence
              documents and file individual files in their proper locations. The program resulted in a significant decrease 
              in non- billable hours and resulted in more client deadlines being met.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}

    </div>
    // End .main-page-wrapper
  );
};

export default WorkExperience;
