const sidebarMenuContent = [
  {
    id: 1,
    name: "Python",
    dropdownList: [
      { name: "Henrique The Bot", routeLink: "/python/henrique" },
      { name: "Henrique The Body", routeLink: "/python/henrique-the-body" },
      { name: "Jeopardy", routeLink: "/python/jeopardy" },
      { name: "PaceMaster", routeLink: "/python/pacemaster" },
    ],
  },
  {
    id: 2,
    name: "Web",
    dropdownList: [
      { name: "This Website", routeLink: "/web/meta" },
      { name: "Coral Safari", routeLink: "/web/coral-safari" },
      { name: "People Clerk", routeLink: "/web/people-clerk" },
      { name: "AC Engineering", routeLink: "/web/ac-engineering" },
    ],
  },
  {
    id: 3,
    name: "iOS",
    dropdownList: [
      { name: "Survey Monkey Rewards", routeLink: "/ios/sm-rewards" },
      { name: "Formula Onedget", routeLink: "/ios/formula-onedget" },
      { name: "PaceMaster", routeLink: "/ios/pacemaster" },
      { name: "Apple Watch Weight App", routeLink: "/ios/apple-watch-weight-app" },
    ],
  },
];

export default sidebarMenuContent;
