import React from "react";
import csMain from "../../../../assets/Web/CoralSafari/CoralSafariMain.png";
import lionfishBarbs from "../../../../assets/iOS/CoralSafari/lionfishBarbs.png";

const CSCleaningContent = () => {
  return (
    <div className="main-page-wrapper">
      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>The Coral Safari</h6>
              <h2 className="page-title font-recoleta">
                How to <span>safely</span> clean lionfish
              </h2>
              <p>How to get them ready to cook</p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={csMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <div>
                <h6 className="sub-heading">but first...</h6>
                <h3 className="title font-recoleta">What to expect</h3>
                <p>
                  One lionfish will produce two filets of extremely white
                  (nearly translucent), flaky meat. It's one of the best tasting
                  fish in our oceans, often being compared to better known fish
                  such as pompano, permit, snook, snapper, hogfish, and grouper.{" "}
                </p>
              </div>

              <div className="border-block mb-50">
                <h3 className="mb-25 font-recoleta">
                  Keep reading or watch the experts:
                </h3>
                <iframe
                  style={{ width: "100%", height: "500px", marginTop: 0 }}
                  src="https://www.youtube.com/embed/vqAhXceCYUk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h3 className="mb-25 mt-100 font-recoleta">
                  To be extra cautious, you can remove the spines all together:
                </h3>
                <iframe
                  style={{ width: "100%", height: "500px", marginTop: 0 }}
                  src="https://www.youtube.com/embed/ZC9r9sddnl0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div>
                <h6 className="sub-heading">Always be careful</h6>
                <h3 className="title font-recoleta">They are dangerous</h3>
                <p>
                  Lionfish hunting can be dangerous due to the venomous spines
                  located on their fins and body. Lionfish have 13 dorsal
                  spines, 3 anal spines, and 2 pelvic spines, all of which are
                  capable of delivering a venomous sting.
                  <br />
                  <br />
                  The spines are used as a defense mechanism to protect the
                  lionfish from predators. When the lionfish feels threatened,
                  it raises its spines, making it difficult to catch using
                  traditional means. The spines are hollow and contain
                  venom-producing cells, which release venom when pressure is
                  applied to the spine. The venom delivered by the lionfish
                  spine can cause extreme pain, swelling, and even paralysis in
                  humans.
                  <br />
                  <br />
                  The lionfish can sting even after death, meaning caution must
                  be used throughout the hunting process. The severity of the
                  sting depends on the amount of venom injected, the location of
                  the sting, and the individual's sensitivity to the venom.
                  <br />
                  <br />
                  Lionfish spines are not only located on their fins but also
                  their body, making them difficult to handle. The spines are
                  sharp and can easily penetrate skin, gloves, and wetsuits.
                  When hunting lionfish, it's important to use appropriate gear,
                  such as a polespear, a lionfish box, and puncture-resistant
                  gloves, and to handle the lionfish with caution.
                </p>
              </div>

              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading mt-75">Cleaning</h6>
              <h3 className="title font-recoleta">
                Prepping the fish to filet:
              </h3>
              <img
                src={lionfishBarbs}
                alt="portfolio details banner"
                className="screen"
                width={"1169px"}
              />
              <br />
              <br />
              <p style={{ marginBottom: "2em" }}>
                After catching and before cooking lionfish, you have to clean
                them. This is the time you are in contact with a lionfish the
                most, so take extra care not to come in contact with any of the
                poisonous barbs. In order to effectively and safely clean the
                lionfish, you will need:
                <br />
                <br />
                <li>
                  Thick gloves. Kevlar and thick rubber gloves are popular
                  choices.{" "}
                </li>
                <li>
                  Heavy duty shears. Garden shears work best, but kitchen shears
                  work as well.{" "}
                </li>
                <li>
                  A sharp fillet knife. Lionfish skin is thin, a sharp knife
                  will make taking of the skin much easer.
                </li>
                <br />
                With protective gloves on, grab the head of the lionfish firmly.
                The fish will be slippery, so grab the head tightly, but not too
                tightly that it will slip out of your grip. Using the shears and
                referencing the diagram above, cut off all protruding spines
                along the back of the lionfish. To be safe, cut them at the base
                where the spines meat the body of the fish. Then, cut off the
                two pelvic fins on either side of the fish's "chest". Finally,
                cut off the 3 anal spines on the bottom side of the fish. If you
                are ever in doubt, cut off any protruding sections of the fish
                (main fins, any spines, tail, etc). You can never remove too
                many. After that, the process is identical to filleting any
                other fish.
              </p>
              <h3 className="title font-recoleta">Filleting a fish:</h3>
              <p>
                Place the fish on a nonstick surface such as a cutting board or
                filet station. A good filet knife is onw with a sharp and
                flexible thin blade. Some people prefer to wear gloves while
                fileting. Rubber gloves aid in grip, while kevlar and chainmail
                gloves prevent accidents with a sharp knife.
                <br />
                <br />
                Create an incision just behing the pectoral fin and gills. The
                incision should run the entire width of the fish, tapering
                towards the front of the fish above the pectoral fin (lots of
                people miss this meat!).
                <br />
                <br />
                Turn the knife horizontally and, starting at the top of the
                incision we made, make another incision running the entire back
                of the fish to the tail. Make sure to feel for the backbone of
                the fish with your knife to ensure that no meat is being missed.
                <br />
                <br />
                Near the tail of the fish, run the knife through the back
                incision we just made until you feel the spine of the fish.
                Carefully guide the knife above and around the spine (this is
                why a flexible blade is key) until the knife pokes out of the
                bottom of the fish. Using the hole we just made in the
                underbelly of the fish, turn your knife around and slice the
                fish from the hole we made to the head, carefully following the
                spine to miss no meat. You will notice a lot of resistance near
                the fish's ribs. This meat is unuseable, so feel free to power
                your way through until this incision meets the initial incision
                we made behind the pectoral fins.
                <br />
                <br />
                You can now peel the entire filet (including the skin) back from
                the rest of the fish. Continue to cut back any meat attached to
                the spine until you are left with a filet of meat held on only
                by the skin connecting to the tail.
                <br />
                <br />
                At the point where the meat meets the skin connected to the
                tail, create an incision between the meat and skin, taking care
                to not break the skin. Continue that incision while holding the
                skin taught and holding the knife at a downwards 45 degree
                angle. This part takes practice, but you should be able to
                continue that incision all the way to the end of the filet until
                you have separated the meat from the skin.
                <br />
                <br />
                Finally, take the separated filet and lay it flat. Press down on
                the meet at different places until you feel the bones that are
                left over from the rib cage (The bones will be very obvious when
                feeling around. They feel like flimsy toothpicks). Very
                generously cut out this bony area, constantly feeling around to
                make sure all bones are removed. It is much better to be safe
                than sorry here, so prioritize bone removal over maximizing the
                amount of meat taken.
                <br />
                <br />
                Now that you're left with one full filet, flip the fish over and
                repeat the process. Make sure to rinse the filets in the sink or
                with a hose to remove any left over scales or entrails. Keep in
                the refrigerator or on ice until ready to cook.
              </p>

              <div className="border-block mb-50">
                <h3 className="mb-25 font-recoleta">
                  What to do if you get stung:
                </h3>
                <p>
                  If you get stung by a lionfish, it's important to take
                  immediate action to minimize the effects of the venom. Here
                  are the steps you should follow:
                  <br />
                  <br />
                  <li>
                    Remove any spines that are still in your skin. Use tweezers
                    or the edge of a credit card to scrape the spines out, being
                    careful not to break them off or push them in further.
                  </li>
                  <br />
                  <li>
                    Immerse the affected area in hot water. The water should be
                    as hot as you can tolerate without causing burns. This will
                    help to neutralize the venom and relieve pain.
                  </li>
                  <br />
                  <li>
                    Clean the wound with soap and water. This will help to
                    prevent infection. Apply a topical pain reliever or
                    anti-inflammatory cream, such as lidocaine or
                    hydrocortisone, to reduce pain and swelling.
                  </li>
                  <br />
                  <li>
                    Seek medical attention if symptoms are severe. If you
                    experience difficulty breathing, chest pain, or other
                    serious symptoms, seek medical attention immediately. It's
                    important to avoid rubbing or massaging the affected area,
                    as this can cause the venom to spread further. Additionally,
                    do not apply ice or vinegar, as these can make the pain
                    worse.
                  </li>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default CSCleaningContent;
