import React from "react";
import csMain from "../../../../assets/Web/CoralSafari/CoralSafariMain.png";

const CSFishingContent = () => {
  return (
    <div className="main-page-wrapper">
      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>The Coral Safari</h6>
              <h2 className="page-title font-recoleta">
                How to <span>safely</span> harvest lionfish
              </h2>
              <p>How to hunt lionfish without getting hurt</p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={csMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <div>
                <h6 className="sub-heading">but first...</h6>
                <h3 className="title font-recoleta">Why should you?</h3>
                <p>To keep it short:</p>
                <li>They don't belong here and are terrible for our reefs</li>
                <li>They're beyond delicious</li>
              </div>

              <div className="border-block mb-50">
                <h3 className="mb-25 font-recoleta">
                  Keep reading or watch an expert:
                </h3>
                <iframe
                  style={{ width: "100%", height: "500px", marginTop: 0 }}
                  src="https://www.youtube.com/embed/4lmeQbtKUUw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div>
                <h6 className="sub-heading">Always be careful</h6>
                <h3 className="title font-recoleta">They are dangerous</h3>
                <p>
                  Lionfish hunting can be dangerous due to the venomous spines
                  located on their fins and body. Lionfish have 13 dorsal
                  spines, 3 anal spines, and 2 pelvic spines, all of which are
                  capable of delivering a venomous sting.
                  <br />
                  <br />
                  The spines are used as a defense mechanism to protect the
                  lionfish from predators. When the lionfish feels threatened,
                  it raises its spines, making it difficult to catch using
                  traditional means. The spines are hollow and contain
                  venom-producing cells, which release venom when pressure is
                  applied to the spine. The venom delivered by the lionfish
                  spine can cause extreme pain, swelling, and even paralysis in
                  humans.
                  <br />
                  <br />
                  The lionfish can sting even after death, meaning caution must
                  be used throughout the hunting process. The severity of the
                  sting depends on the amount of venom injected, the location of
                  the sting, and the individual's sensitivity to the venom.
                  <br />
                  <br />
                  Lionfish spines are not only located on their fins but also
                  their body, making them difficult to handle. The spines are
                  sharp and can easily penetrate skin, gloves, and wetsuits.
                  When hunting lionfish, it's important to use appropriate gear,
                  such as a polespear, a lionfish box, and puncture-resistant
                  gloves, and to handle the lionfish with caution.
                </p>
              </div>

              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading mt-75">Catching</h6>
              <h3 className="title font-recoleta">Getting Started:</h3>
              <p style={{ marginBottom: "2em" }}>
                If you're interested in getting started with diving and
                spearfishing, there are a few things you can do to prepare
                yourself. Firstly, consider taking a course in freediving and/or
                spearfishing. If you plan on hunting lionfish with scuba gear,
                you need to get your scuba certification first. This will teach
                you the basics of breathing techniques, equalization, and
                safety. 
                <br />
                <br />
                Secondly, invest in high-quality gear, such as a
                wetsuit, fins, and a mask. Make sure the gear is made by a free
                diving company such as Cressi unless you pan to hunt lionfish
                using scuba gear. Ensure your gear fits well and is comfortable.
                <br />
                <br />
                Thirdly, start practicing in a pool or calm body of water. This
                will help you get used to the feeling of being underwater and
                improve your technique. It is also essential to research the
                local laws and regulations for spearfishing in your area and
                ensure you have any necessary permits or licenses. In Florida,
                you need a special permit to hunt lionfish in protected parks.
                Besides that, there are no regulations for harvesting lionfish.
                When starting, begin with small targets and work your way up,
                practicing your aim and technique before attempting to hunt
                larger fish. Lionfish are generally not spooked easily, so take
                your time when aligning your shot.
                <br />
                <br />
                Remember, always prioritize safety when freediving and
                spearfishing. Never dive alone and always have a buddy nearby.
                Keep an eye on your air supply (for scuba) and never push
                yourself beyond your limits.
              </p>
              <h3 className="title font-recoleta">You need the right spear</h3>
              <p>
                The most popular spear types used to hunt lionfish are the
                Hawaiian sling, the pole spear, and the Lionfish Pole Spear. The
                Hawaiian sling is a type of spear that is propelled by a rubber
                band, while the pole spear is a long shaft with a pointed end.
                The Lionfish Pole Spear is specifically designed to capture
                lionfish and has a trident-like pronged tip to prevent them from
                slipping off.
              </p>

              <h6 className="sub-heading mt-75">Storing</h6>
              <h3 className="title font-recoleta">Not just any bag will do</h3>
              <p>
                When hunting lionfish, it's important to have a safe and secure
                way to store them. The Zookeeper Lionfish Containment Unit is a
                popular model of lionfish bag that provides a safe and effective
                way to store lionfish. It has rigid walls that prevent the
                lionfish from escaping and stinging, and a trap at the top to
                prevent them from getting out.
                <br />
                <br />
                To use the Zookeeper Lionfish Containment Unit, simply stick
                your pole spear inside the trap at the top and deposit the
                lionfish inside. The trap prevents the lionfish from escaping
                while allowing you to safely store them until you are ready to
                dispose of them.
                <br />
                <br />
                When storing lionfish, it's important to handle them with
                caution and avoid coming into contact with their venomous
                spines. Always wear appropriate gloves and make sure the only
                thing coming into contact with the lionfish is your spear.
              </p>

              <div className="border-block mb-50">
                <h3 className="mb-25 font-recoleta">
                  What to do if you get stung:
                </h3>
                <p>
                  If you get stung by a lionfish, it's important to take
                  immediate action to minimize the effects of the venom. Here
                  are the steps you should follow:
                  <br />
                  <br />
                  <li>
                    Remove any spines that are still in your skin. Use tweezers
                    or the edge of a credit card to scrape the spines out, being
                    careful not to break them off or push them in further.
                  </li>
                  <br />
                  <li>
                    Immerse the affected area in hot water. The water should be
                    as hot as you can tolerate without causing burns. This will
                    help to neutralize the venom and relieve pain.
                  </li>
                  <br />
                  <li>
                    Clean the wound with soap and water. This will help to
                    prevent infection. Apply a topical pain reliever or
                    anti-inflammatory cream, such as lidocaine or
                    hydrocortisone, to reduce pain and swelling.
                  </li>
                  <br />
                  <li>
                    Seek medical attention if symptoms are severe. If you
                    experience difficulty breathing, chest pain, or other
                    serious symptoms, seek medical attention immediately. It's
                    important to avoid rubbing or massaging the affected area,
                    as this can cause the venom to spread further. Additionally,
                    do not apply ice or vinegar, as these can make the pain
                    worse.
                  </li>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default CSFishingContent;
