import React from "react";

import "../assets/fonts/bootstrap-icons/font-css.css"

const CSHeader = () => {

  return (
    <header
      className={`theme-alt-menu sticky-menu theme-menu-one dark-bg  `}
    >
      <div className="inner-content">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{marginLeft: "1em", marginTop: "1.25em", marginBottom: "1.25em"}} className="logo">
            <i style={{color: 'white'}}className="bi-arrow-down" />
          </div>
          {/* End .logo */}

          <div className="center-widget d-flex align-items-center">
            <h4 style={{color: 'white', marginTop: "0.5em"}} className="page-title font-recoleta">
              Swipe This Down To Exit
            </h4>
          </div>

          <div style={{marginRight: "1em", marginTop: "1.25em", marginBottom: "1.25em"}}>
            <i style={{color: 'white'}}className="bi-arrow-down" />
          </div>
          {/* <!-- /.right-widget --> */}
        </div>
      </div>
      {/* <!-- /.inner-content --> */}
    </header>
    // {/* <!-- /.theme-main-menu --> */}
  );
};

export default CSHeader;