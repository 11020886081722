import React from "react";
import GalleryCarousel from "../../../../components/GalleryCarousel";

// Python
import henrique from "../../../../assets/Python/Henrique/henriqueHome.jpg";
import henriqueTheBody from "../../../../assets/Python/HenriqueTheBody/htbHome.jpg";
import jeopardy from "../../../../assets/Python/Jeopardy/jeopardyHome.png";
import paceMaster from "../../../../assets/Python/PaceMaster/PaceMasterHome.png";

// Web
import thisWebsite from "../../../../assets/Web/ThisWebsite/thisWebsiteHome.png";
import coralSafari from "../../../../assets/Web/CoralSafari/CoralSafariHome.png";
import ACEngineering from "../../../../assets/Web/ACEngineering/ACEngineeringHome.png";
import peopleCLerk from "../../../../assets/Web/PeopleClerk/PeopleClerkHome.png";

// iOS
import SMRewards from "../../../../assets/iOS/SMRewards/SMRewardsHome.jpeg";
import PaceMaster from "../../../../assets/iOS/PaceMaster/PaceMasterHome.png";
import AWWeight from "../../../../assets/iOS/AWWeight/AWWeightHome.png";
import FormulaOnedget from "../../../../assets/iOS/FormulaOnedget/FormulaOnedgetHome.png";

const pythonProjects = [
  {
    img: henrique,
    title: "Henrique The Bot",
    meta: "Discord Bot",
    link: "/python/henrique"
  },
  {
    img: henriqueTheBody,
    title: "Henrique The Body",
    meta: "Discord Bot Body",
    link: "/python/henrique-the-body"
  },
  {
    img: jeopardy,
    title: "Jeopardy",
    meta: "Party Game",
    link: "/python/jeopardy"
  },
  {
    img: paceMaster,
    title: "PaceMaster",
    meta: "Workout Aid",
    link: "/python/pacemaster"
  },
];

const webProjects = [
  {
    img: thisWebsite,
    title: "This Website",
    meta: "My Portfolio ",
    link: "/web/meta"
  },
  {
    img: coralSafari,
    title: "Coral Safari",
    meta: "Invasive Species Geolocation",
    link: "/web/coral-safari"
  },
  {
    img: peopleCLerk,
    title: "People Clerk",
    meta: "Small Claims Extraordinaire",
    link: "/web/people-clerk"
  },
  {
    img: ACEngineering,
    title: "AC Engineering",
    meta: "Engineering Contractors",
    link: "/web/ac-engineering"
  }
];

const iosProjects = [
  {
    img: SMRewards,
    title: "SM Rewards",
    meta: "Rewards For Your Opinion",
    link: "/ios/sm-rewards"
  },
  {
    img: FormulaOnedget,
    title: "Formula Onedget",
    meta: "Anything F1 Whenever, Wherever",
    link: "/ios/formula-onedget"
  },
  {
    img: PaceMaster,
    title: "PaceMaster",
    meta: "Workout Aid In Your Pocket",
    link: "/ios/pacemaster"
  },
  {
    img: AWWeight,
    title: "Apple Watch Weight App",
    meta: "Know Your Body",
    link: "/ios/apple-watch-weight-app"
  }
];

const Projects = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		    --> */}
      <div className="vcamp-text-block-one pt-180 md-pt-130">
        <div className="container">
          <div className="pb-75 md-pb-50">
            <div className="row">
              <div className="col-12 m-auto">
                <div className="text-wrapper text-center ps-0">
                  <div className="title-style-one">
                    <h3 className="title">Here's My Playground </h3>
                  </div>
                  <p className="text-lg pt-35 lg-pt-30">
                    A (hopefully) up to date list of what I'm working on
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.Inside Page Banner --> */}

      {/* <!--
			=====================================================
				Portfolio Gallery Python
			=====================================================
			--> */}
      <div className="portfolio-gallery-eight border-top pt-130 pb-150 lg-pt-80 lg-pb-80">
        <div className="container">
          <div className="slider-wrapper position-relative">
            <div className="portfolio-slider-two hvr-overlay-right galley-item-wrapper gap15 slick-rounded-arrow arrow-center ripple-btn is_light">
              <h4 className="sub-heading mb-50" style={{marginLeft: 25}}>Python Projects</h4>
              <GalleryCarousel portfolioContent={pythonProjects} />
            </div>
            {/* <!-- /.portfolio-slider-two --> */}
          </div>
          {/* <!-- /.slider-wrapper --> */}
        </div>
      </div>
      {/* <!-- /.portfolio-gallery-eight --> */}

      {/* <!--
			=====================================================
				Portfolio Gallery Web
			=====================================================
			--> */}
      <div className="portfolio-gallery-eight border-top pt-130 pb-150 lg-pt-80 lg-pb-80">
        <div className="container">
          <div className="slider-wrapper position-relative">
            <div className="portfolio-slider-two hvr-overlay-right galley-item-wrapper gap15 slick-rounded-arrow arrow-center ripple-btn is_light">
              <h4 className="sub-heading mb-50" style={{marginLeft: 25}}>Web Projects</h4>
              <GalleryCarousel portfolioContent={webProjects} />
            </div>
            {/* <!-- /.portfolio-slider-two --> */}
          </div>
          {/* <!-- /.slider-wrapper --> */}
        </div>
      </div>
      {/* <!-- /.portfolio-gallery-eight --> */}

      {/* <!--
			=====================================================
				Portfolio Gallery iOS
			=====================================================
			--> */}
      <div className="portfolio-gallery-eight border-top pt-130 pb-150 lg-pt-80 lg-pb-80">
        <div className="container">
          <div className="slider-wrapper position-relative">
            <div className="portfolio-slider-two hvr-overlay-right galley-item-wrapper gap15 slick-rounded-arrow arrow-center ripple-btn is_light">
              <h4 className="sub-heading mb-50" style={{marginLeft: 25}}>iOS Projects</h4>
              <GalleryCarousel portfolioContent={iosProjects} />
            </div>
            {/* <!-- /.portfolio-slider-two --> */}
          </div>
          {/* <!-- /.slider-wrapper --> */}
        </div>
      </div>
      {/* <!-- /.portfolio-gallery-eight --> */}

    </div>
    // End .main-page-wrapper
  );
};

export default Projects;
