import React from "react";
import { useSearchParams } from "react-router-dom";

const FaqContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = searchParams.get("tab") === null ? "website" : searchParams.get("tab");

  const handleSelect = (e) => {
    setSearchParams({"tab": e.replace('#', '')});
  }

  const menuList = [
    { id: 1, name: "Website", isActive: "", targetNav: "#website" },
    { id: 2, name: "Formula Onedget", isActive: "", targetNav: "#formulaonedget" },
    // { id: 3, name: "PaceMaster", isActive: "", targetNav: "pacemaster" },
  ];
  return (
    <div className="col-xxl-9 col-xl-10 m-auto">
      <ul className="nav nav-tabs justify-content-center" role="tablist">
        {menuList.map((menu) => (
          <li className="nav-item" key={menu.id}>
            <FaqTab
              className={`nav-link ${tabIndex === menu.targetNav.replace('#', '') ? "active" : ""}`}
              data-bs-toggle="tab"
              data-bs-target={menu.targetNav}
              onClick={e => handleSelect(menu.targetNav)}
              role="tab"
              text={menu.name}
              doesClick={menu.id === 1 ? true : tabIndex === menu.targetNav.replace('#', '') ? true : false}
            >
              {menu.name}
            </FaqTab>
          </li>
        ))}
      </ul>
      {/* End nav-item */}

      <div className="tab-content mt-110 lg-mt-60">
        <div className="tab-pane fade show active" id="website" role="tabpanel">
          <div className="accordion accordion-style-three" id="accordionTwo">
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                >
                  Privacy Policy
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionTwo"
              >
                <div className="accordion-body">
                  <p>
                    No cookies are used on this website. The only information collected is your contact information IF you choose to send me a message on the contact form. 
                    The data will only be used within the bounds of that conversation. Your information will NEVER be sold. The only instance any information will be collected 
                    is if the contact form is abused and your information is added to a block list. 
                  </p>
                </div >
              </div >
            </div >
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                >
                  Terms of Use
                </button>
              </div>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionTwo"
              >
                <div className="accordion-body">
                  <p>
                    Please do not spam me. If the contact form/other forms of messaging are abused you will be blocked on all fronts. <br /> <br />
                    Do not claim any of my work as your own.
                  </p>
                </div>
              </div>
            </div>
          </div >
  {/* <!-- /.accordion-style-three --> */ }
        </div >
        <div className="tab-pane fade" id="formulaonedget" role="tabpanel">
          <div className="accordion accordion-style-three" id="accordionFour">
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwoB">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwoB"
                >
                  Privacy Policy
                </button>
              </div>
              <div
                id="collapseTwoB"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFour"
              >
                <div className="accordion-body">
                  <p>
                    Privacy of your information will always be my highest priority. Absolutely no personal information is collected or used by Formula Onedget at any point 
                    while using the app. 
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingThreeB">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThreeB"
                >
                  Disclaimer and Acknowledgements
                </button>
              </div>
              <div
                id="collapseThreeB"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFour"
              >
                <div className="accordion-body">
                  <p>
                  This website and Formula Onedget are unofficial and are not associated in any way with the Formula 1 companies. F1, FORMULA ONE, FORMULA 1, FIA FORMULA ONE WORLD CHAMPIONSHIP, GRAND PRIX 
                  and related marks are trade marks of Formula One Licensing B.V. 
                  </p>
                  <br />
                  <p>
                  All race / driver / constructor information is gathered using the Ergast Developer API, of which I am not affiliated with. You can read more about them <a href="http://ergast.com/mrd" style={{color: "red", textDecoration: "underline"}}>here.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.accordion-style-three --> */}
        </div>
        <div className="tab-pane fade" id="pacemaster" role="tabpanel">
          <div className="accordion accordion-style-three" id="accordionFive">
            <div className="accordion-item">
              <div className="accordion-header" id="headingOneC">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOneC"
                >
                  This Is Filler Text For Now
                </button>
              </div>
              <div
                id="collapseOneC"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFive"
              >
                <div className="accordion-body">
                  <p>
                    It will be filled in soon. I do not collect data but Google maps might in the contact page. 
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwoC">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwoC"
                >
                  This Is Filler Text For Now
                </button>
              </div>
              <div
                id="collapseTwoC"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFive"
              >
                <div className="accordion-body">
                 <p>
                    It will be filled in soon. I do not collect data but Google maps might in the contact page. 
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingThreeC">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThreeC"
                >
                  This Is Filler Text For Now
                </button>
              </div>
              <div
                id="collapseThreeC"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFive"
              >
                <div className="accordion-body">
                  <p>
                    It will be filled in soon. I do not collect data but Google maps might in the contact page. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.accordion-style-three --> */}
        </div>
      </div >
  {/* <!-- /.tab-content --> */ }

{/* <!-- /.request-faq --> */ }
    </div >
  );
};

export default FaqContent;

class FaqTab extends React.Component {
  constructor(props) {
    super(props)
    this.button = React.createRef()
  }

  componentDidMount() {
    if (this.props.doesClick) {
      this.button.current.click();
    }
  }

  render() {
    return (
      <button 
        ref={this.button} 
        className={this.props.className} 
        data-bs-target={this.props["data-bs-target"]}
        data-bs-toggle={this.props["data-bs-toggle"]}
        onClick={this.props.onClick}
        role={this.props.role}
      >
          {this.props.text}
      </button>
    )
  }
}

