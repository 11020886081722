import React from "react";

import thisWebsiteMain from "../../../assets/Web/ThisWebsite/thisWebsiteMain.png";

const ThisWebsiteContent = () => {

  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>My Portfolio</h6>
              <h2 className="page-title font-recoleta">
                Gathering <span>Everything</span> in one spot
              </h2>
              <p>
                5+ years in the making
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={thisWebsiteMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About My Portfolio</h3>
              <p>
                There's not much I can say about this that you can't readily see, on account of that you're here, you know?
              </p>
              <p>
                I'd been putting off making this website for quite some time, and was finally motivated to make it when I 
                realized I needed a website to release apps on the Apple App Store. 
              </p>
              <h6 className="sub-heading mt-100">how it's made</h6>
              <p>
                I made this website in ReactJs. I encourage you to explore the site and check out my projects!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default ThisWebsiteContent;
