import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTopOnNav from "../components/ScrollToTopOnNav";

// Misc Pages
import Home from "../Pages/Home";
import Contact from "../Pages/Contact";

// Python
import Henrique from "../Pages/Python/Henrique";
import HenriqueTheBody from "../Pages/Python/HenriqueTheBody";
import Jeopardy from "../Pages/Python/Jeopardy";
import PaceMasterPython from "../Pages/Python/PaceMaster";
import Concert from "../Pages/Python/Concert";

// Web
import ThisWebsite from "../Pages/Web/ThisWebsite";
import CoralSafari from "../Pages/Web/CoralSafari";
import ACEngineering from "../Pages/Web/ACEngineering";
import PeopleClerk from "../Pages/Web/PeopleClerk";

// iOS
import SMRewards from "../Pages/iOS/SMRewards";
import PaceMaster from "../Pages/iOS/PaceMaster";
import AWWeight from "../Pages/iOS/AWWeight";
import FormulaOnedget from "../Pages/iOS/FormulaOnedget";

// Secret Stuff For Coral Safari 
import CSFishing from "../Pages/iOS/CoralSafari/Fishing";
import CSCleaning from "../Pages/iOS/CoralSafari/Cleaning";
import CSCooking from "../Pages/iOS/CoralSafari/Cooking";

import Faq from "../Pages/FAQ";
import NotFound from "../Pages/NotFound";

const AllRoutes = () => {
  return (
    <>
      <ScrollToTopOnNav />
      <Routes>
        {/* All home */}
        <Route path="/" element={<Home />} />
        <Route path="/python/henrique" element={<Henrique />} />
        <Route path="/python/henrique-the-body" element={<HenriqueTheBody />} />
        <Route path="/python/jeopardy" element={<Jeopardy />} />
        <Route path="/python/pacemaster" element={<PaceMasterPython />} />
        <Route path="/python/api/concert" element={<Concert />} />
        <Route path="/web/meta" element={<ThisWebsite />} />
        <Route path="/web/coral-safari" element={<CoralSafari />} />
        <Route path="/web/ac-engineering" element={<ACEngineering />} />
        <Route path="/web/people-clerk" element={<PeopleClerk />} />
        <Route path="/ios/sm-rewards" element={<SMRewards />} />
        <Route path="/ios/pacemaster" element={<PaceMaster />} />
        <Route path="/ios/apple-watch-weight-app" element={<AWWeight />} />
        <Route path="/ios/formula-onedget" element={<FormulaOnedget />} />
        <Route path="/ios/coral-safari/how-to-catch" element={<CSFishing />} />
        <Route path="/ios/coral-safari/how-to-clean" element={<CSCleaning />} />
        <Route path="/ios/coral-safari/how-to-cook" element={<CSCooking />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
