import React from "react";
import Seo from "../../components/Seo";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import HTBContent from "../helperPages/Python/HTBContent";

const Henrique = () => {
    return (
      <div className="main-page-wrapper light-bg">
        <Seo title="Henrique The Body" />
        {/* End Seo Related data */}
  
        {/* <!-- 
        =============================================
        Sidebar Nav
        ============================================== 
          --> */}
        <Sidebar className="dark-style" />
        {/* End Sidebar */}
  
        {/* <!-- 
              =============================================
                  Theme Main Menu
              ============================================== 
              --> */}
        <Header />
        {/* End Header */}
        <HTBContent />
        <Footer />
      </div>
      // End .main-page-wrapper
    );
  };
  
  export default Henrique;
  