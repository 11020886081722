import React from "react";

import FOMain from "../../../assets/iOS/FormulaOnedget/FormulaOnedgetMain.png";
import FOLogo from "../../../assets/iOS/FormulaOnedget/FormulaOnedgetLogo.png";
import DriversWidget  from "../../../assets/iOS/FormulaOnedget/DriversStandingsWidget.PNG";
import ConstructorsWidget  from "../../../assets/iOS/FormulaOnedget/ConstructorsStandingsWidget.PNG";
import ScheduleWidget  from "../../../assets/iOS/FormulaOnedget/ScheduleWidget.PNG";
import Drivers  from "../../../assets/iOS/FormulaOnedget/Drivers.PNG";
import Constructors  from "../../../assets/iOS/FormulaOnedget/Constructors.PNG";
import Schedule  from "../../../assets/iOS/FormulaOnedget/Schedule.PNG";
import Past  from "../../../assets/iOS/FormulaOnedget/PastRace.gif";
import Future  from "../../../assets/iOS/FormulaOnedget/FutureRace.PNG";

const FormulaOnedgetContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Formula Onedget</h6>
              <h2 className="page-title font-recoleta">
                My take on a <span>Formula 1</span> app
              </h2>
              <p>
                Everything you need for F1 right there where you look the most
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={FOMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
            style={{height: "10%"}}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h3 className="font-recoleta mb-100">For Privacy Policy, please click <a href="http://localhost:3000/faq?tab=formulaonedget" style={{color: "blue"}}>here</a></h3>
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">Formula Onedget</h3>
              <p>
                You know, there's a few great Formula 1 apps out there already, so I knew I had to do something special for mine. I wanted all the information 
                you could want presented as <span style={{textDecoration: "underline", color: "red", fontSize: "25px"}}>Widgets</span> on your homescreen. As 
                my first iOS app made from start to finish, I wanted it to look <span style={{textDecoration: "underline", color: "red", fontSize: "25px"}}>Clean</span> 
                &nbsp;and <span style={{textDecoration: "underline", color: "red", fontSize: "25px"}}>Professional.</span> Most of all, I wanted it to be completely 
                and utterly <span style={{textDecoration: "underline", color: "red", fontSize: "25px"}}>Free</span> with no strings attached. 
              </p>
              <h3 className="mb-25 font-recoleta">So I Present to You:</h3>
              <div className="border-block mb-50" style={{justifyContent: "center", display: "flex"}}> 
                <img
                    src={FOLogo}
                    alt="popup"
                    title="Click for large view"
                    style={{width: "50%"}}
                />
              </div>
              <div className="mb-100">
                <h6 className="sub-heading">What's included</h6>
                <h3 className="title font-recoleta">The Whole Package</h3>
                <p>
                    <span style={{fontWeight: "900", fontSize: "22px"}}>Three widgets and counting:</span> Drivers Championship Standings, 
                    Constructors Championship Standings, and the Upcoming Race.
                </p>
                <div className="row mb-50">
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={DriversWidget}
                            alt="popup"
                            title="Click for large view"
                            style={{width: "50%"}}
                        />
                    </div>
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={ConstructorsWidget}
                            alt="popup"
                            title="Click for large view"
                            style={{width: "50%"}}
                        />
                    </div>
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={ScheduleWidget}
                            alt="popup"
                            title="Click for large view"
                        />
                    </div>
                </div>
                <p>
                    <span style={{fontWeight: "900", fontSize: "22px"}}>Three information pages:</span> Drivers Championship Standings, 
                    Constructors Championship Standings, and the Complete Season Schedule.
                </p>
                <div className="row mb-50">
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={Drivers}
                            alt="popup"
                            title="Click for large view"
                        />
                    </div>
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={Constructors}
                            alt="popup"
                            title="Click for large view"
                        />
                    </div>
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={Schedule}
                            alt="popup"
                            title="Click for large view"
                        />
                    </div>
                </div>
                <p>
                    <span style={{fontWeight: "900", fontSize: "22px"}}>In depth race information:</span> Past and Future Races
                </p>
                <div className="row mb-50" style={{justifyContent: "center", display: "flex"}}>
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={Past}
                            alt="popup"
                            title="Click for large view"
                        />
                    </div>
                    <div
                        className="col-sm-4"
                        data-aos="fade-left"
                        style={{justifyContent: "center", display: "flex", marginTop: "1em"}}
                    >
                        <img
                            src={Future}
                            alt="popup"
                            title="Click for large view"
                        />
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <p style={{fontSize: 15}}>
                        (You may have noticed that race timer has a light in it. As the time counts down to zero each category will convert to 5 red lights)
                    </p>
                </div>
              </div>
              <div>
                <h6 className="sub-heading">the boring technical stuff</h6>
                <h3 className="title font-recoleta">How It's Made</h3>
                <p>
                  Formula Onedget is written in 100% vanilla storyboard Swift. It relies heavily on the <a href="https://ergast.com/mrd/" style={{color: "blue"}}>Ergast Formula 1 API</a> to
                  deliver all the race / driver / constructor information. The widgets are written in vanilla SwiftUI. The code is 100% open source and can be viewed on my 
                  <a href="https://github.com/helloitseddie/FormulaOnedget" style={{color: "blue"}}>&nbsp;Github</a>. (Please note that the code is a mess and will be refactored closer to its release
                  on the App Store. If the code is not a mess then I haven't gotten around to removing this message yet.)
                </p>
                <p>
                  Formula Onedget will be a live service once released. Everything is subject to change and will probably be continuously revised!
                </p>
              </div>
              {/* <!-- /.border-block --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default FormulaOnedgetContent;
