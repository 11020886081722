import React from "react";

import PaceMasterMain from "../../../assets/Python/PaceMaster/PaceMasterMain.png";

const PaceMasterPythonContent = () => {
  
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>PaceMaster</h6>
              <h2 className="page-title font-recoleta">
                The <span>Brain</span> Behind My Workout Aid
              </h2>
              <p>
                A comprehensive Spotify tool
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={PaceMasterMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About PaceMaster</h3>
              <p>
               This project focuses on the backend to my PaceMaster project. If you want a more comprehensive look at the full project, including images,
               please check it out <a href="/ios/pacemaster" style={{color: "blue"}}><nobr>here</nobr></a>.
              </p>
              <p>
               PaceMaster is my workout tool for Spotify users. It connects to your account and builds playlists for you based on your 
               workout goal. For running, it takes into account your stride length and mile time goals to generate your optimal running 
               tempo. For spinning, it takes your rpm goal to generate your optimal tempo. It then takes that tempo and creates playlists 
               for you with songs with that tempo. It uses Spotify's inbuilt recommendation system, along with your preferred genres, to
               tailor your workout songs to your taste. 
              </p>
              <div>
                <h6 className="sub-heading mt-100">a deep dive into the spotify developer api</h6>
                <h3 className="title font-recoleta">
                  The Code
                </h3>
                <p>
                  PaceMaster is my most recent project and in active development. As of now, it makes extensive use of the Spotify Developer API, 
                  the Python requests library, and the requests_oauthlib library. The program has you confirm your account using Spotify's OAuth2 
                  pipeline, calculates your ideal tempo using your stride and mile time or your riding rpm, then generates a list of songs given 
                  your preferred genre. It then optionally automatically creates the playlist on your Spotify account. 
                </p>
                <p>
                  There are a few issues with the project, notably the drop off in available music with certain higher tempos (around 175+), my 
                  understanding of the Spotify OAuth2 flow, and my understanding of the legal implications of using Spotify's technology/platform/likeness.
                </p>

                <h3 className="title font-recoleta mt-100">The Future</h3>
                <p>
                  Of course, this project is fairly early on in development while I wrap up a few other projects, so I'm not sure what the future holds. 
                  My ultimate goal is to make an <a href="/ios/PaceMaster" style={{color: "blue"}}><nobr>app</nobr></a> with free and paid version and 
                  release it on the App Store. However, as I mentioned, this depends heavily on the legal implications. Make sure to check back every once
                  in a while to keep up to date with the development process!
                </p>
              </div>
              {/* <!-- /.border-block --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default PaceMasterPythonContent;