import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

import AppleWatch from "../../../assets/iOS/AWWeight/AppleWatch.gif";
import AWWeightGraph from "../../../assets/iOS/AWWeight/AWWeightGraph.png";
import AWWeightHome from "../../../assets/iOS/AWWeight/AWWeightHome.png";

const AWWeightContent = () => {
  const popupGallery = [
    {
      id: 1,
      img: AWWeightGraph,
      animation: "fade-left",
      width: 460,
      height: 400,
    },
    {
      id: 2,
      img: AWWeightHome,
      animation: "fade-left",
      width: 460,
      height: 400,
    },
    {
      id: 3,
      img: AppleWatch,
      animation: "fade-right",
      width: 460,
      height: 400,
    },
  ];
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Apple Watch Weight App</h6>
              <h2 className="page-title font-recoleta">
                A Senior Design Project Made With <span>Love</span> and SwiftUI
              </h2>
              <p style={{marginBottom: "5em"}}>
                When hardware and software meet
              </p>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">Choosing a Senior Design Project</h3>
              <p>
                When I first signed up for senior design, I had originally wanted to do something with <a href="/web/coral-safari" style={{color: "blue"}}>The Coral Safari</a>.
                Unfortunately, the course was at its cap for new projects. Instead, I decided to put my newfound iOS dev <a href="/?tab=experience" style={{color: "blue"}}>&nbsp;experience&nbsp;</a>
                to good use. So I joined a blank iOS project in its first semester where I could help shape the look and feel of the finished product more than I could to 
                a well established project.
              </p>
              <div className="border-block">
                <h6 className="sub-heading">The (unnamed) Apple Watch Weight App</h6>
                <h3 className="title font-recoleta">
                  The Project
                </h3>
                <p>
                  The project is very cool. A computer engineering student is making a bluetooth enabled weight scale that will be used in conjunction with our iOS/WatchOS app. The 
                  scale will communicate the weight of the user through bluetooth to their phone or watch, which will keep track of their weight over time and/or throughout the day. 
                  The target audience is comprised of athletes who need quick and precise weight measurements often. 
                </p>
                <p>
                  Below is the progress that has been made so far in the semester.
                </p>
                <Gallery>
                  <div className="row">
                    {popupGallery.map((item) => (
                      <div
                        className="col-sm-6"
                        data-aos={item.animation}
                        key={item.id}
                      >
                        <div className="img-meta">
                          <Item
                            original={item.img}
                            thumbnail={item.img}
                            width={item.width}
                            height={item.height}
                          >
                            {({ ref, open }) => (
                              <img
                                className="w-100 cursor-pointer"
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt="popup"
                                title="Click for large view"
                              />
                            )}
                          </Item>
                        </div>
                      </div>
                    ))}
                  </div>
                </Gallery>

                <h3 className="title font-recoleta">Frameworks</h3>
                <p>
                  The frontend of the WatchOS app (my contribution to the project) is written in 100% vanilla SwiftUI with a target WatchOS of WatchOS 9.0 (needed for SwiftCharts).
                  The weight data is either recieved from the connected iOS device or read in through Microsoft Azure. 
                </p>

                <h3 className="font-recoleta mt-100">Updates</h3>
                <p>
                  This page will be updated through my final two semesters at Florida International University (Spring 2022 - Fall 2023)
                </p>
              </div>
              {/* <!-- /.border-block --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default AWWeightContent;