import React from "react";
import location from "../../assets/Contact/icon_17.svg";
import email from "../../assets/Contact/icon_18.svg";
import linkedin from "../../assets/Contact/linkedin.png";

const Address = () => {
  const addressContent = [
    {
      id: 1,
      title: "Location",
      descriptions: (
        <>
          <p>
            Miami, Florida
          </p>
        </>
      ),
      delayAnimatinTime: 0,
      icon: location,
    },
    {
      id: 2,
      title: "Email",
      descriptions: (
        <>
          <p>
            Send a message below or <br />
            <a href="mailto:eddieabriscoe@gmail.com" className="call">
              Email Me
            </a>
          </p>
        </>
      ),
      delayAnimatinTime: 100,
      icon: email,
    },
    {
      id: 3,
      title: "Connect with me",
      descriptions: (
        <>
          <p>
            Message me on <br />
            <a href="https://www.linkedin.com/in/edward-a-briscoe/" className="call">
              LinkedIn
            </a>
          </p>
        </>
      ),
      delayAnimatinTime: 200,
      icon: linkedin,
    },
  ];
  return (
    <>
      {addressContent.map((val) => (
        <div
          className="col-md-4"
          data-aos="fade-up"
          data-aos-delay={val.delayAnimatinTime}
          key={val.id}
        >
          <div className="address-block-one text-center mb-40">
            <div className="icon d-flex align-items-center justify-content-center m-auto">
              {console.log(val.icon)}
              <img src={val.icon} alt="icon" style={{width: 30}} />
            </div>
            <h5 className="title">{val.title}</h5>
            {val.descriptions}
          </div>
          {/* <!-- /.address-block-one --> */}
        </div>
      ))}
    </>
  );
};

export default Address;
