import React from "react";

import PCMain from "../../../assets/Web/PeopleClerk/PeopleClerkMain.png";
import Resume from "../../../assets/files/EABResume.pdf";

const PeopleClerkContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>People Clerk</h6>
              <h2 className="page-title font-recoleta">
                <span>The</span> small claims court assistant
              </h2>
              <p>
                Make sure to check them out <a href="https://www.peopleclerk.com/" style={{color: "blue"}}>here</a>.
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={PCMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About My People Clerk Experience</h3>
              <p>
                I owe nearly all my web development experience to the people at People Clerk. While I <a href="/?tab=experience" style={{color: "blue"}}>worked there</a>, 
                I was given the freedom to work and learn at my own pace, which helped me thrive and grow as a web developer. If you need small claims court help, I cannot
                recommend them enough. 
              </p>
              <div className="border-block mb-50">
                <h6 className="sub-heading">Improvements and Solutions</h6>
                <h3 className="title font-recoleta">
                  What I did 
                </h3>
                <p>
                  While I worked at People Clerk, I learned ReactJs, GraphQL, Formik, Material-UI, Jest, Unit Testing, Firebase, Contentful, and the Google API suite.   
                </p>
                <p>
                  As tangible contributions from start to finish, I was able to increase the speed/optimization of the help center through the use of Contentful and GraphQL over the original 
                  Firebase implementation, I was able to add an address autocomplete function to client onboarding using the Google API suite and Formik. 
                </p>
                <p>
                  It's pretty easy to see how a lot of these frameworks/experiences have made their way into a lot of my other projects. I am extremely grateful to the experiences People Clerk 
                  gave me. Make sure to check them out <a href="https://www.peopleclerk.com/" style={{color: "blue"}}>here</a>!
                </p>
                <p>
                  Want to learn more about my work at People Clerk? Check out my <a href="/?tab=experience" style={{color: "blue"}}>work experience</a> or download
                  my <a href={Resume} download style={{color: "blue"}}>resume</a>!
                </p>
              </div>
              {/* <!-- /.border-block --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default PeopleClerkContent;