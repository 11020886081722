import React from "react";

const Recipe = ({ title, link, image, description }) => {
  return (
    <>
      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="container">
        <div
          className="row"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <div className="col-xl-9 col-xl-10 m-auto">
            <div
              style={{
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h3 className="mb-25 font-recoleta">{title}</h3>
              <img
                src={image}
                alt="portfolio details banner"
                className="screen"
                width={"1169px"}
                style={{ marginBottom: "2.5em" }}
              />
              <p>{description}</p>
              <a href={`${link}`} style={{ color: "orange" }}>
                Click here to try it!
              </a>
            </div>
          </div>
          <hr
            style={{
              border: "none",
              borderTop: "1px solid #4F4F4F",
              marginBottom: "6em",
              marginTop: "4em",
              padding: 0,
              width: "50%",
            }}
          />
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </>
    // End .main-page-wrapper
  );
};

export default Recipe;
