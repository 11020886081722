import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import Address from "../components/Contact/Address";
import CopyrightFooter from "../components/Footer/CopyrightFooter";
import ContactForm from "../components/Contact/ContactForm";
import Seo from "../components/Seo";

const Contact = () => {
  return (
    <div className="main-page-wrapper">
      <Seo title="Contact Me" />
      {/* End Seo Related data */}
      <Sidebar className="dark-style" />
      <Header />

      {/* <!-- 
			=============================================
				Inside Page Banner
			============================================== 
			--> */}
      <div className="inside-hero-three" style={{background: "#FDFBF9"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <h2 className="page-title font-recoleta">
                Send me a <span>Message</span>
              </h2>
            </div>
            <div className="col-xxl-5 col-md-6 ms-auto">
              <p>
                Get in contact with me! <br /> <br /> Questions? Comments? Concerns? Let me know, I'll try 
                my best to get back to you as soon as I can!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.inside-hero-three --> */}

      {/* <!--
        =====================================================
        Contact Section One
        =====================================================
        --> */}
      <div className="contact-section-five mt-150">
        <div className="container">
          <div className="row">
            <Address />
          </div>
        </div>
        {/* End .container */}

        <div className="contact-meta mt-130 lg-mt-60">
          <div className="row gx-0">
            <div className="col-xl-6 order-xl-last d-flex">
              <div className="form-wrapper dark-bg w-100">
                <div className="form-style-three">
                  <h3 className="form-title font-recoleta pb-30 lg-pb-20">
                    Say Hello!
                  </h3>
                  <ContactForm className="theme-btn-four " />
                </div>
                {/* <!-- /.form-style-three --> */}
              </div>
              {/* <!-- /.form-wrapper --> */}
            </div>
            {/* End .col */}

            <div className="col-xl-6 order-xl-first d-flex">
              <div className="map-area-two h-100 w-100">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      title="map"
                      className="gmap_iframe"
                      src="https://maps.google.com/maps?q=Miami&t=&z=11&ie=UTF8&iwloc=&output=embed"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
        {/* <!-- /.contact-meta --> */}
      </div>
      {/* <!-- /.contact-section-one --> */}
      
      <div className="vcamp-footer-one box-layout" style={{background: "#FDFBF9"}}>
            <div className="container">
              <div className="row">
                <div className="col-xxl-11 m-auto">
                  <div className="bottom-footer">
                    <CopyrightFooter />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    // End .main-page-wrapper
  );
};

export default Contact;
