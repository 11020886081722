import React from "react";
import Seo from "../../components/Seo";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import CoralSafariContent from "../helperPages/Web/CoralSafariContent";

const CoralSafari = () => {
    return (
      <div className="main-page-wrapper light-bg">
        <Seo title="Coral Safari" />
        {/* End Seo Related data */}
  
        {/* <!-- 
        =============================================
        Sidebar Nav
        ============================================== 
          --> */}
        <Sidebar className="dark-style" />
        {/* End Sidebar */}
  
        {/* <!-- 
              =============================================
                  Theme Main Menu
              ============================================== 
              --> */}
        <Header />
        {/* End Header */}
        <CoralSafariContent />
        <Footer />
      </div>
      // End .main-page-wrapper
    );
  };
  
  export default CoralSafari;
  