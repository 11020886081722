import rec1 from "../../../../assets/iOS/CoralSafari/rec1.png";
import rec2 from "../../../../assets/iOS/CoralSafari/rec2.png";
import rec3 from "../../../../assets/iOS/CoralSafari/rec3.png";
import rec4 from "../../../../assets/iOS/CoralSafari/rec4.png";
import rec5 from "../../../../assets/iOS/CoralSafari/rec5.png";

const recipes = [
  {
    title: "Castaway's wreck diver-style lionfish",
    img: rec1,
    link: "https://rantsfrommycrazykitchen.com/castaways-wreck-diver-style-lionfish/",
    description:
      "Courtesy of Castaway Waterfront Restaurant and Sushi Bar in Marathon Key, Florida. Chef John has shared one of his favorite lionfish recipes to try at home or the next time you are in Marathon.",
  },
  {
    title: "Blackened Lionfish with Creamy Potato Salad",
    img: rec2,
    link: "https://www.cookingchanneltv.com/recipes/blackened-lionfish-with-creamy-potato-salad-2125061",
    description:
      "Elevate your seafood game with blackening seasoning! The perfect blend of spices to give your fish a deliciously smoky flavor, with just the right amount of heat. Our seasoning brings out the best in any fish, but does wonders to lionfish. Try out this recipe and taste the difference! Recipe courtesy of Emily Marcus.",
  },
  {
    title: "Lionfish Fritters with Red Bell Pepper Coulis",
    img: rec3,
    link: "https://www.myrecipes.com/recipe/lionfish-fritters-red-bell-pepper-coulis",
    description:
      "Homemade, fresh seafood fritters can be on your table in 40 minutes. Red Bell Peppper Coulis makes the perfect dipping sauce.",
  },
  {
    title: "Lionfish Ceviche",
    img: rec4,
    link: "https://grillgirl.com/2017/05/lionfish-ceviche-eat-em-beat-em/",
    description:
      "This Lionfish Ceviche is a great and unique appetizer or side dish that is light and refreshing, but also full of flavor! If you can't find Lionfish or don't like Lionfish, any Florida whitefish can be substituted for this recipe. I like my Ceviche with a little kick (like a Peruvian style) but you can omit the peppers if you don’t like spice.",
  },
  {
    title: "BBQ Lionfish With Orange And Almond Slaw",
    img: rec5,
    link: "https://barbecuebible.com/recipe/bbq-lionfish-with-orange-and-almond-slaw/",
    description:
      "Everyone loves a summer BBQ, and fantastic coleslaw can be a game changer. This tangy coleslaw recipe with crunchy and aromatic additions of fennel, orange, and almonds might even steal the show. Other fish that I like for BBQ include mahi-mahi, king mackerel, and mullet.",
  },
];

export default recipes;
