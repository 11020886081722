import React from "react";

import SMMain from "../../../assets/Home/Work/SM.png";
import Resume from "../../../assets/files/EABResume.pdf";

const SMRewardsContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Survey Monkey Rewards</h6>
              <h2 className="page-title font-recoleta">
                Real World Mobile Development <span>Experience</span>
              </h2>
              <p>
                Ah, the golden summer of &thinsp;'22
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={SMMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About My Internship</h3>
              <p>
                I was lucky enough to intern with <a href="/?tab=experience" style={{color: "blue"}}>Momentive (Survey Monkey)</a> during the Summer of 2022. I worked with an 
                amazing team, and was able to make real contributions I could be proud of. I was assigned the Mobile team where I was able to gain experience in 
                mobile development, iOS development, reactive programming, and systems integration by developing real improvements to the Survey Monkey Rewards App.
              </p>
              <div className="border-block mb-50">
                <h6 className="sub-heading">Improvements and Solutions</h6>
                <h3 className="title font-recoleta">
                  What I did 
                </h3>
                <p>
                  I'm not completely sure how much I can say about what I changed in the app, so I will try to keep it vague. I can say that I was able to learn RxSwift 
                  and develop a custom WKWebView to bring the functionality of a third party web app to our native iOS app without the need of extra pods or frameworks. 
                  I was also able to quickly adapt to a codebase that hadn't been touched (in some areas) in multiple years.  
                </p>
                <p>
                  Want to learn more about my work at Momentive (Survey Monkey)? Check out my <a href="/?tab=experience" style={{color: "blue"}}>experiences</a> or download
                  my <a href={Resume} download style={{color: "blue"}}>resume</a>!
                </p>
              </div>
              {/* <!-- /.border-block --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default SMRewardsContent;