import React from "react";

import PaceMasterMain from "../../../assets/Python/PaceMaster/PaceMasterMain.png";

const PaceMasterContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>PaceMaster</h6>
              <h2 className="page-title font-recoleta">
                <span>Your</span> Music. <span>Your</span> Workout.
              </h2>
              <p>
                Custom playlists for your custom needs
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={PaceMasterMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About PaceMaster</h3>
              <p>
                PaceMaster is my workout tool for Spotify users. It connects to your account and builds playlists for you based on your 
                workout goal. For running, it takes into account your stride length and mile time goals to generate your optimal running 
                tempo. For spinning, it takes your rpm goal to generate your optimal tempo. It then takes that tempo and creates playlists 
                for you with songs with that tempo. It uses Spotify's inbuilt recommendation system, along with your preferred genres, to
                tailor your workout songs to your taste. 
              </p>
              <p>
                I used to listen to audiobooks when I worked out to take my mind off of exercise, but then one day I finished my audiobook
                and Gwen Stefani's Rich Girl started playing. Imaging my surprise when I was spinning and I found myself peddling at the perfect(ish)
                tempo for my goal. The song was actually 98bpm and I was going for 100bpm at the time. It genuinely helped me enjoy and do the workout
                much better than I had ever been able to before. Thus PaceMaster was born.
              </p>
              <div className="border-block mb-50">
                <h6 className="sub-heading">Development updates</h6>
                <h3 className="title font-recoleta">
                  Early Stages
                </h3>
                <p>
                  PaceMaster is in my queue of current projects, mainly <a href="/web/meta" style={{color: "blue"}}><nobr>this website</nobr></a> and 
                  <a href="/ios/formula-onedget" style={{color: "blue"}}>&nbsp;my F1 app</a>. A backend prototype/proof of concept has already been
                  <a href="/python/pacemaster" style={{color: "blue"}}>&nbsp;made</a>. Beyond that, not much development has been put in to the 
                  front end iOS app yet. Once some of my other projects have begun to wrap up, this project will begin serious development. 
                </p>
              </div>
              {/* <!-- /.border-block --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default PaceMasterContent;