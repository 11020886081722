import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import Masonry from "react-masonry-css";

import htbMain from "../../../assets/Python/HenriqueTheBody/htbMain.PNG";
import htbHome from "../../../assets/Python/HenriqueTheBody/htbHome.jpg";
import htbPoke from "../../../assets/Python/HenriqueTheBody/htbPoke.gif";

// animations
import angryJoin from "../../../assets/Python/HenriqueTheBody/Animations/angryJoin.jpeg";
import blink from "../../../assets/Python/HenriqueTheBody/Animations/blink.jpeg";
import csgo from "../../../assets/Python/HenriqueTheBody/Animations/csgo.jpeg";
import gum from "../../../assets/Python/HenriqueTheBody/Animations/gum.gif";
import happyJoin from "../../../assets/Python/HenriqueTheBody/Animations/happyJoin.jpeg";
import idle1 from "../../../assets/Python/HenriqueTheBody/Animations/idle1.jpeg";
import idle2 from "../../../assets/Python/HenriqueTheBody/Animations/idle2.jpeg";
import idle3 from "../../../assets/Python/HenriqueTheBody/Animations/idle3.jpeg";
import idle4 from "../../../assets/Python/HenriqueTheBody/Animations/idle4.jpeg";
import leave from "../../../assets/Python/HenriqueTheBody/Animations/leave.jpeg";
import minecraft from "../../../assets/Python/HenriqueTheBody/Animations/minecraft.jpeg";
import music from "../../../assets/Python/HenriqueTheBody/Animations/music.gif";
import normal from "../../../assets/Python/HenriqueTheBody/Animations/normal.jpeg";
import poke from "../../../assets/Python/HenriqueTheBody/Animations/poke.jpeg";
import russianRoulette from "../../../assets/Python/HenriqueTheBody/Animations/russianRoulette.jpeg";
import sleep from "../../../assets/Python/HenriqueTheBody/Animations/sleep.gif";

const HTBContent = () => {
  const popupGallery = [
    {
      id: 1,
      img: htbHome,
      animation: "fade-right",
      width: 460,
      height: 400,
    },
    {
      id: 2,
      img: htbPoke,
      animation: "fade-left",
      width: 460,
      height: 400,
    },
  ];
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Henrique The Bot's Body</h6>
              <h2 className="page-title font-recoleta">
                Giving My Friend A Little More <span>Presence</span>
              </h2>
              <p>
                A Raspberry Pi + A Little Magic
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={htbMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About Henrique's New Robot Body</h3>
              <p>
                If you're confused as to who Henrique is, read up about him <a href="/python/henrique" style={{color: "blue"}}><nobr>here</nobr></a>. <br /> <br />
                As Henrique evolved and developed his own personality, I wanted him to have a more substancial presence. As luck would have it, my 
                girlfriend had a Raspberry Pi that she wasn't using, so a plan was formed. Make a Python program run on the Raspberry Pi that would
                interact with Henrique the bot and make a desk ornament that would react to what Henrique was up to.
              </p>
              <div className="border-block">
                <h6 className="sub-heading">From VSCode to My Desk</h6>
                <h3 className="title font-recoleta">
                  The Design Process
                </h3>
                <p>
                  The goal was simple, give Henrique a bunch of animations and have them play when the command was executed. The scope was then
                  widened a bit to include some idle animations, and an animation when the touchscreen was poked. The difficulty lied in needing
                  multiple threads to listen out for commands from discord, listening for the poke, having an idle timer, and a fun fact timer,
                  while the main thread was taken by PyQT. Having the different threads interact with each other and STILL be able to change values
                  in the main GUI thread was a nightmare. Lots and lots of trial and error perservered and now he starts up when my computer turns on, 
                  has a whole suite of animations, and gives me a fun fact in English or Spanish every 5 minutes.
                </p>
                <p>
                  Here is his Raspberry Pi setup (left) and his poking animation (right)
                </p>
                <Gallery>
                  <div className="row">
                    {popupGallery.map((item) => (
                      <div
                        className="col-sm-6"
                        data-aos={item.animation}
                        key={item.id}
                      >
                        <div className="img-meta">
                          <Item
                            original={item.img}
                            thumbnail={item.img}
                            width={item.width}
                            height={item.height}
                          >
                            {({ ref, open }) => (
                              <img
                                className="w-100 cursor-pointer"
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt="popup"
                                title="Click for large view"
                              />
                            )}
                          </Item>
                        </div>
                      </div>
                    ))}
                  </div>
                </Gallery>

                <h3 className="title font-recoleta">Frameworks</h3>
                <p>
                  Again, Henrique relies on Contentful as the content delivery method for his animations. Every second the Pi checks Contentful to
                  see if any actions have been activated from Discord and then plays the corresponding animation. The bot uploads his action to 
                  Contentful right before executing it on Discord. The GUI relies on PyQt, the Python wrapper for Qt and renders several images
                  in sequence to form animations. Asyncio and multithreading were utilized to wrap the different processes together. 
                </p>
              </div>
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading">Result</h6>
              <h3 className="title font-recoleta">A Hyperactive Beauty</h3>
              <p>
                Here are his animations. Hover over to see what commands/circumstances lead to them:
              </p>
              <AnimationMasonry />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default HTBContent;

const AnimationMasonry = () => {
    const breakpointColumnsObj = {
      default: 4
    };

    const animations = [
          {
            img: angryJoin,
            title: "When someone he doesn't like joins a voice chat",
            meta: "Angry Henrique",
            width: 425,
            height: 560,
          },
          {
            img: blink,
            title: "When idling and he fancies a blink",
            meta: "Idling Henrique",
            width: 425,
            height: 560,
          },
          {
            img: csgo,
            title: "When we're playing CS:GO",
            meta: "CS:GO Henrique",
            width: 425,
            height: 560,
          },
          {
            img: gum,
            title: "When idling and he wants some gum",
            meta: "Idling Henrique",
            width: 425,
            height: 560,
          },
          {
            img: happyJoin,
            title: "When someone he likes joins a voice chat",
            meta: "Happy Henrique",
            width: 425,
            height: 560,
          },
          {
            img: idle1,
            title: "When idling and he feels silly",
            meta: "Idling Henrique",
            width: 425,
            height: 560,
          },
          {
            img: idle2,
            title: "When idling and he feels silly",
            meta: "Idling Henrique",
            width: 425,
            height: 560,
          },
          {
            img: idle3,
            title: "When idling and he feels silly",
            meta: "Idling Henrique",
            width: 425,
            height: 560,
          },
          {
            img: idle4,
            title: "When idling and he feels bored",
            meta: "Idling Henrique",
            width: 425,
            height: 560,
          },
          {
            img: leave,
            title: "When someone leaves a voice chat",
            meta: "Sad Henrique",
            width: 425,
            height: 560,
          },
          {
            img: minecraft,
            title: "When we're playing Minecraft",
            meta: "Minecraft Henrique",
            width: 425,
            height: 560,
          },
          {
            img: music,
            title: "When he's streaming music",
            meta: "Dancing Henrique",
            width: 425,
            height: 560,
          },
          {
            img: normal,
            title: "His natural state",
            meta: "Henrique",
            width: 425,
            height: 560,
          },
          {
            img: russianRoulette,
            title: "When playing russian roulette",
            meta: "Intimidating Henrique",
            width: 425,
            height: 560,
          },
          {
            img: poke,
            title: "When I poke him",
            meta: "Pillsbury Dough Boy Henrique",
            width: 425,
            height: 560,
          },
          {
            img: sleep,
            title: "When he's been idling too long",
            meta: "Sleepy Henrique",
            width: 425,
            height: 560,
          },
        ];

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    shuffleArray(animations);

    return (
      <>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid hvr-overlay-right galley-item-wrapper"
            columnClassName="my-masonry-grid_column"
        >
            {animations.map((item, i) => (
            <div className="isotop-item" key={i} data-aos="fade-right">
                <div className="gallery-item mb-40 lg-mb-30">
                <div className="img-holder">
                    <img
                    src={item.img}
                    alt="portfolio"
                    className="img-meta w-100 tran5s"
                    />
                    <div className="caption d-flex justify-content-end align-items-center flex-column" style={{textAlign: "center"}}>
                        <h6 style={{color: "white"}}>
                            {item.title}
                        </h6>
                        <span className="tag">{item.meta}</span>
                    </div>
                </div>
                </div>
                {/* <!-- /.gallery-item --> */}
            </div>
            ))}
        </Masonry>
      </>
    );
  };
