import React from "react";
import Seo from "../../../components/Seo";
import CSHeader from "../../../components/CSHeader";

import CSFishingContent from "../../helperPages/iOS/CoralSafari/FishingContent";

const CSFishing = () => {
    return (
      <div className="main-page-wrapper light-bg">
        <Seo title="How to catch a lionfish" />

        {/* <!-- 
              =============================================
                  Theme Main Menu
              ============================================== 
              --> */}
        <CSHeader />
        {/* End Header */}
        <CSFishingContent />
      </div>
      // End .main-page-wrapper
    );
  };
  
  export default CSFishing;
  