import React from "react";

const Skills = () => {
  return (
    <div className="main-page-wrapper">
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Fast learning, very adaptible, extremely driven</h6>
              <h2 className="page-title font-recoleta">
                Here's what <span>I</span> bring to the table
              </h2>
              <div className="mb-100" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h3 className="title font-recoleta">Languages</h3>
              <h4>Natural</h4>
              <p>
                Fluent in English and Spanish
              </p>
              <h4>Computer</h4>
              <p className="mt-25">
                Extremely Knowledgable in Python, Swift, JavaScript, HTML, and CSS
              </p>
              <p>
                Comfortable in Matlab, Java, C#, and SQL
              </p>
              <p>
                Familiar with C, C++, and Kotlin
              </p>
              <div className="border-block"> 
                <h3 className="title font-recoleta">Frameworks</h3>
                <p>
                  Experience with RxSwift, Xcode, ReactJs, GraphQL, Unity, RDD Systems, AGILE, REST, 
                  Jupyter Notebook, sklearn, MatplotLib, Pandas, Numpy, PyQT, and Contentful

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}

      
    </div>
    // End .main-page-wrapper
  );
};

export default Skills;
