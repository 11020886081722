import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import FaqContent from "./helperPages/FAQContent";
import Seo from "../components/Seo";

const Faq = () => {
  return (
    <div className="main-page-wrapper">
      <Seo title="FAQ" />

      <Sidebar className="dark-style" />
      <Header />

      <div className="inside-hero-three">
        <div className="container">
          <div className="row">
            <div className="">
              <h2 className="page-title font-recoleta" style={{textAlign: "center"}}>
                Find <span>Answers</span> here.
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.inside-hero-one --> */}
      
      <div className="faq-section-two dark-bg mb-70">
        <div className="container">
          <div className="row">
            <FaqContent />
          </div>
        </div>
        {/* <!-- /.container --> */}
      </div>
      {/* <!-- /.faq-section-two --> */}
      <Footer />
    </div>
    // End .main-page-wrapper
  );
};

export default Faq;
