import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AboutMe from "./tabs/AboutMe";
import Education from "./tabs/Education";
import Skills from "./tabs/Skills";
import WorkExperience from "./tabs/WorkExperience";
import Projects from "./tabs/Projects";

const HomeTabs = () => {
  const TabListContent = [
    "About Me",
    "Education",
    "Skills",
    "Work Experience",
    "Projects"
  ];
  const portfolioContent = [
    {
      id: 1,
      component: <AboutMe />
    },
    {
      id: 2,
      component: <Education />
    },
    {
      id: 3,
      component: <Skills />
    },
    {
      id: 4,
      component: <WorkExperience />
    },
    {
      id: 5,
      component: <Projects />
    },
  ];

  const getTabVal = (stringVal) => {
    var tabVal = 0;
    switch(stringVal) {
      case "about":
        tabVal = 0;
        break;
      case "education":
        tabVal = 1;
        break;
      case "skills":
        tabVal = 2;
        break;
      case "experience":
        tabVal = 3;
        break;
      case "projects":
        tabVal = 4;
        break;
      default:
        tabVal = 0;
    }
    return tabVal
  }

  const getStringVal = (tabVal) => {
    var stringVal = 0;
    switch(tabVal) {
      case 0:
        stringVal = "about";
        break;
      case 1:
        stringVal = "education";
        break;
      case 2:
        stringVal = "skills";
        break;
      case 3:
        stringVal = "experience";
        break;
      case 4:
        stringVal = "projects";
        break;
      default:
        stringVal = "about";
    }
    return stringVal
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = getTabVal(searchParams.get("tab"));

  const [selectedVal, setSelected] = useState(tabIndex);

  const handleSelect = (e) => {
    setSelected(e);
    setSearchParams({"tab": getStringVal(e)});
  }

  return (
    <>
      <Tabs selectedIndex={selectedVal} onSelect={(e) => handleSelect(e)}>
        <TabList className="controls control-nav-one text-center mb-1 lg-mb-3">
          {TabListContent.map((tab, i) => (
            <Tab className="control" key={i} style={{marginTop: "1em"}}>
              {tab}
            </Tab>
          ))}
        </TabList>

        {portfolioContent.map((item) => (
          <TabPanel key={item.id}>
            <div className="row">
              <div className="col-xxl-11 m-auto">
                <div className="mixitUp-container clearfix">
                  {item.component !== undefined && item.component}
                </div>
              </div>
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default HomeTabs;
