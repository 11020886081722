import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import Masonry from "react-masonry-css";

import henriqueMain from "../../../assets/Python/Henrique/henriqueMain.jpg";
import music from "../../../assets/Python/Henrique/music.png";
import wordle from "../../../assets/Python/Henrique/wordle.png";
import henriqueHappy from "../../../assets/Python/Henrique/henriqueHappy.jpg";

const HenriqueContent = () => {
  const popupGallery = [
    {
      id: 1,
      img: music,
      animation: "fade-right",
      width: 460,
      height: 400,
    },
    {
      id: 2,
      img: wordle,
      animation: "fade-left",
      width: 460,
      height: 400,
    },
  ];
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Henrique The Bot</h6>
              <h2 className="page-title font-recoleta">
                Building <span>the</span> Discord Bot
              </h2>
              <p>
                An all emcompassing helper for all things Discord
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={henriqueMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About Henrique</h3>
              <p>
                Henrique was thought up a long time ago when all my friends and I moved to different schools across the country.
                We would get on Discord and think "man I wish we could play party games here". So I made Henrique. In his infancy/version 1
                state, he only had three commands: !drink (randomly choose someone to take a sip from their drink), !russianRoulette (anyone in 
                a voice chat would be a part of the game), and !kingsCup (a classic card game). I was extremely proud of his ability to shuffle
                cards and draw them without duplicates. Now he is on version 150ish and can do a whole lot more. From making mini games out of 
                whatever video game we're playing, to playing music, jeopardy, and wordle, he's got us covered.
              </p>
              <h3 className="mb-25 font-recoleta">Current Commands:</h3>
              <div className="border-block mb-50"> <CommandMasonry /> </div>
              <div>
                <h6 className="sub-heading">150+ versions later</h6>
                <h3 className="title font-recoleta">
                  The Design Process
                </h3>
                <p>
                  Henrique has grown from about 50 lines of code to thousands. Each version (except for a few debugging versions) added some sort
                  of functionality to him as my friends and I saw fit. When we wanted to play card games, he became a dealer. When we wanted mini games 
                  while we were playing Minecraft or Counter Strike, he developed the mini games. When Wordle became the craze, he got a daily-wordle-by-dm 
                  game, as well as a party wordle where people can play together. We loved trivia, so Henrique downloaded every Jeopardy question from the last 
                  20 years and made a game out of it. His most recent big addition, music, pairs with your spotify playlists to stream your own music from YouTube.
                  Each new addition was added one step at a time when someone came up with an idea.
                </p>
                <p>
                  Here are some shots of him in action playing my spotify playlist (left) and playing wordle (right)
                </p>
                <Gallery>
                  <div className="row">
                    {popupGallery.map((item) => (
                      <div
                        className="col-sm-6"
                        data-aos={item.animation}
                        key={item.id}
                      >
                        <div className="img-meta">
                          <Item
                            original={item.img}
                            thumbnail={item.img}
                            width={item.width}
                            height={item.height}
                          >
                            {({ ref, open }) => (
                              <img
                                className="w-100 cursor-pointer"
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt="popup"
                                title="Click for large view"
                              />
                            )}
                          </Item>
                        </div>
                      </div>
                    ))}
                  </div>
                </Gallery>

                <h3 className="title font-recoleta">Frameworks</h3>
                <p>
                  Henrique is written entirely in Python and hosted in the cloud via Heroku. He, of course, makes use of plenty of libraries,
                  most notably discord, asyncio, random, contentful, contentful_management, json, collections, datetime, os, spotipy, enum, 
                  and yt_dlp.
                </p>
                <p>
                  He also makes use of the spotify developer api for reading playlists, the youtube playback api for streaming music, and most
                  importantly, contentful as a CDN. Contentful allows him to keep track of the music queue, track statistics for fun facts about himeself
                  (think spotify wrapped), keep a history of who has completed the wordle, keep track of his own suggestion box, and update his  
                  <a href="/python/henrique-the-body" style={{color: "blue"}}><nobr>&nbsp;mood</nobr></a>.
                </p>
              </div>
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading">Result</h6>
              <h3 className="title font-recoleta">Perfection</h3>
              <p>
                A robot that has genuinely developed his own sense of personality as a mix of mine and my friends'.
              </p>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={henriqueHappy}
                    thumbnail={henriqueHappy}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={henriqueHappy}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <p>
                Development on Henrique has slowed down in recent months, as has my Discord activity, in favor of some of my other
                projects. I'm sure there's plenty in store for him in the future, but I'm not quite sure what that is yet. Stay 
                tuned, I'll make sure to keep you updated!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default HenriqueContent;

const CommandMasonry = () => {
    const breakpointColumnsObj = {
        default: 4
      };

    const commands = ["!drink", "!russianRoulette", "!pull", "!end", "!kingsCup", "!draw", "!up", 
    "!minecraft", "!buy", "!buildIdea", "!gun", "!leeroyJenkins", "!strat", "!strat t -or- ct", "!strat <map> ct -or- t", 
    "!election", "!lang", "!partywordle", "!guess <xxxxx>", "!wordle", "!jeopardy", "!teenjeopardy", "!goatjeopardy", "!choose <->",
    "!whatIs <xxxxx>", "!search <xxxx>", "!play <x>", "!add <x>", "!yoink <x>", "!play", "!skip", "!transfer", "!showq", "!pause",
    "!resume", "!stop", "!wrapped", "!top25", "!reset", "!listplaylists", "!playlist", "!send", "!battleBit", "!legoStarWars", 
    "!suggest <xxxx>", "!bonk <name>", "!honoryourfamily", "!football", "!roll", "!split"]

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    shuffleArray(commands);

  
    return (
      <>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {commands.map((command, i) => (
                <div key={i}>{command}</div>
            ))}
        </Masonry>
      </>
    );
  };
