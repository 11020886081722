import React from "react";
import CopyrightFooter from "./CopyrightFooter";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
        <div className="fancy-banner-three bg-white mt-40 mb-40">
            <div className="inner-content position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                            <h2 className="title font-recoleta text-center text-md-start">
                                Have any <span>questions</span>?
                            </h2>
                            </div>
                            {/* End .col */}
                            <div className="col-lg-6 col-md-4 d-flex justify-content-md-end justify-content-center">
                            <Link
                                to="/contact"
                                className="theme-btn-four ripple-btn sm-mt-30"
                            >
                                Contact Me!
                            </Link>
                            </div>
                            {/* End .col */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="vcamp-footer-one box-layout" style={{background: "#FDFBF9"}}>
            <div className="container">
                <div className="row">
                <div className="col-xxl-11 m-auto">
                    <div className="bottom-footer">
                    <CopyrightFooter />
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer;