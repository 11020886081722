import React from "react";

const AboutMe = () => {
  return (
    <div className="main-page-wrapper">
      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one mt-0">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">Who Am I</h3>
              <p>
                Hi! My name's Eddie and this is my portfolio website. I don't know why you're here, but I hope you enjoy what I have to offer! Kick back, relax, and enjoy, friend.
              </p>
              <p>
                If you want to learn some more about me and what I do, keep reading below. If you want to see what I've done, click the "Projects" tab above
                or click the 4 dots in the top right hand corner. If you want to see my qualifications click on the "Education", "Skills", and "Work Experience" 
                tabs above or download my resume from the 4 dots in the top right hand corner.
              </p>
              <div className="border-block">
                <h6 className="sub-heading">education</h6>
                <h3 className="title font-recoleta">
                  How Did I Get Here
                </h3>
                <p>
                  I am from Miami, Florida and went to highschool at Belen Jesuit Preparatory Academy where I focused on as many STEM courses as I could
                  to further my dream of becoming a Mechanical Engineer. 
                </p>
                <p>
                  After graduating highschool, I went to Purdue University in their first year engineering program. I discovered my love for programming 
                  around this time and taught myself Python, C, and Swift. Eventually I transfered to Computer Engineering as I fully committed to 
                  programming over Mechanical Engineering.
                </p>
                <p>
                  During Covid, I tranferred to Florida International University where I now study Computer Science!
                </p>
              </div>
              <div className="border-bottom mb-100">
                <h6 className="sub-heading">projects</h6>
                <h3 className="title font-recoleta">What Am I Doing</h3>
                <p>
                  Most projects I make are things that I wanted to use but could not find readily available already. Henrique the Bot evolved
                  so my friends and I could play fun, customized games on Discord. Formula Onedget was made so I didn't have to pay to have
                  Formula 1 information on my homescreen. Coral Safari was made to hopefully help my friends and I catch more lionfish. I have 
                  plenty of projects to show you, so make sure to check them out!
                </p>
              </div>
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading">My Hobbies</h6>
              <h3 className="title font-recoleta">What Do I Do For Fun</h3>
              <p>
                I have an extremely short attention span when it comes to hobbies, so I tend to accumulate a lot of them. I will now 
                attempt to list them all for you below:
              </p>
              <p>
                Cooking, Baking, Boating, Fishing, Diving, Spearfishing, Snorkeling, Video Games, Cars, Driving, Football, Baseball, 
                Coding, Bongos, Piano, Wearing Cowboy Boots, Ukelele, Legos, Comedy, Building Things, Biking, Going to the Beach, Target Shooting, 
                Hunting, Swimming, Formula One, Painting, Drawing, Writing, Exploring, Inventing, Watch Collecting, Mixology, and Travelling.
              </p>
              <p>
                I was expecting that to be a lot longer list, but thinking of those hobbies was the hardest part of making this website.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default AboutMe;
