import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

import jeopardyMain from "../../../assets/Python/Jeopardy/jeopardyMain.png";
import jeopardyBoard from "../../../assets/Python/Jeopardy/jeopardyBoard.png";
import q1 from "../../../assets/Python/Jeopardy/q1.png";
import q2 from "../../../assets/Python/Jeopardy/q2.png";

const JeopardyContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>Jeopardy Party Game</h6>
              <h2 className="page-title font-recoleta">
                A gift to my trivia <span>obsessed</span> girlfriend
              </h2>
              <p>
                This is unaffiliated with the hit game show Jeopardy, they don't know it exists (please don't sue me)
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={jeopardyMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About My Jeopardy Game</h3>
              <p>
                Allow me to paint a picture for you. I like Jeopardy and so does my girlfriend (and <a href="/python/henrique" style={{color: "blue"}}>Henrique</a>).
                Her birthday is coming up and she says she wants to have a Jeopardy tournament with our friends. I want to flex my Python GUI muscles. I say "don't worry,
                I know what to do." I make Jeopardy. It plays Jeopardy sounds. Alex Trebek introduces me, the host. It's a good gift. All is well in the world.
              </p>
              <div className="border-block mb-50">
                <h6 className="sub-heading">Born from necessity.</h6>
                <h3 className="title font-recoleta">
                  The Design Process
                </h3>
                <p>
                  Ok, I didn't actually crack my knuckles and say "don't worry, I know what to do." In an effort to flex my Python GUI muscles. Instead, I tried to be
                  as lazy as possible and Googled "Jeopardy online party game thing". The problem is I was also writing all the questions and I didn't want to have to write 
                  each question (for 9 rounds, remember it's a tournament) then input each question and then play each game. 
                </p>
                <p>
                  Eventually I decided to make it myself because I had to prewrite each game and then instantly switch test banks between tournament rounds. So I made a scoreboard,
                  a variable player scoring system, a daily double system, a betting system, a double and final jeopardy, sound effects, transitions, and polish. I started off trying
                  to be lazy and ended up making the most comprehensive homebrewed custom Jeopardy game available (at least within the first page of Google).
                </p>
                <h3 className="title font-recoleta mt-100">Frameworks</h3>
                <p>
                  The GUI is completely PyQt5, the Python wrapper for Qt. The whole thing is basically a 6x6 grid of buttons for the categories and a big label for the question. It also 
                  has 3 scenes loaded and you switch between them for Jeopardy, double Jeopardy, and final Jeopardy. The sounds are played using the module playsound. A lot of audio editing 
                  went in to making it sound like an actual episode. Openpyxl was used to read in the questions and answers from prewritten excel sheets. Finally threading was used to play sounds
                  and certain transitions at the same time. 
                </p>
              </div>
              {/* <!-- /.border-block --> */}
              <h3 className="title font-recoleta">
                  Screenshots
              </h3>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={jeopardyBoard}
                    thumbnail={jeopardyBoard}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={jeopardyBoard}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <h6 className="sub-heading" style={{textAlign: "center", marginBottom: "5em"}}>Board Overview</h6>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={q1}
                    thumbnail={q1}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={q1}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <h6 className="sub-heading mb-75" style={{textAlign: "center"}}>Sample Question</h6>
              <p style={{textAlign: "center", marginBottom: "5em"}}>
                  (Stuart Little Dipper)
              </p>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={q2}
                    thumbnail={q2}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={q2}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <h6 className="sub-heading mb-75" style={{textAlign: "center"}}>Sample Question</h6>
              <p style={{textAlign: "center", marginBottom: "5em"}}>
                  (Endzones)
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default JeopardyContent;