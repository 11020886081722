import React from "react";
import csMain from "../../../../assets/Web/CoralSafari/CoralSafariMain.png";

import recipes from "./Recipes";
import Recipe from "./Recipe";

const CSCookingContent = () => {
  return (
    <div className="main-page-wrapper">
      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>The Coral Safari</h6>
              <h2 className="page-title font-recoleta">
                How to turn lionfish into a <span>delicious</span> meal
              </h2>
              <p>Now all your hard work pays off</p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={csMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <div className="border-block mb-50">
                <h6 className="sub-heading">but first...</h6>
                <h3 className="title font-recoleta">
                  An aside from the author
                </h3>
                <p>
                  Hello! My name is Eddie Briscoe, I am the creator of The Coral
                  Safari and the author of this article. I wanted to put in to
                  perspective just <i>how</i> good lionfish is to eat.
                  <br />
                  <br />
                  I have been fishing and eating fish for over twenty years. I
                  have eaten every fish I can legally catch in South Florida,
                  including some big names like:
                  <br />
                  <br />
                  <li>Permit</li>
                  <li>Pompano</li>
                  <li>Snook</li>
                  <li>Grouper</li>
                  <li>Snapper</li>
                  <li>Hogfish</li>
                  <li>Barracuda</li>
                  <li>Tripletail</li>
                  <li>Dolphin (Mahi-Mahi, not Flipper!)</li>
                  <li>Tuna (blackfin and yellowfin)</li>
                  <br />
                  <br />
                  Out of all of these, I would choose lionfish 10/10 times. It
                  is the pinnacle of white, flaky meat. There's no doubt in my
                  mind that lionfish is the best tasting fish in our oceans.
                </p>
              </div>

              <div>
                <h6 className="sub-heading">The meat</h6>
                <h3 className="title font-recoleta">What's it like?</h3>
                <p>
                  Lionfish meat is white and flaky with a slight buttery taste.
                  It lends itself well to recipes for all the fish mentioned in
                  the previous section besides tuna. Some popular preparations
                  include blackening, grilling, pan frying, breading, baking,
                  ceviche, or even sashimi!
                  <br />
                  <br />
                  We've gathered a list of popular lionfish recipes on the
                  internet below. Make sure to check back often, we update these
                  recipes frequently!
                </p>
              </div>

              {/* <!-- /.border-block --> */}

              <hr style={{border: "none", borderTop: "1px solid #4F4F4F", marginBottom: "6em", marginTop: "6em", padding: 0}} />

              {recipes.map((recipe) => {
                return <Recipe title={recipe.title} link={recipe.link} image={recipe.img} description={recipe.description}/>
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default CSCookingContent;
