import React from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header";
import HomeTabs from "./HomeTabs";

import myImg from "../../../assets/Home/itMe.jpg";

const HomeContent = () => {

  return (
    <div className="main-page-wrapper">
      {/* End Seo Related data */}

      {/* <!-- 
      =============================================
      Sidebar Nav
      ============================================== 
        --> */}
      <Sidebar />

      {/* <!--
        =============================================
        Theme Main Menu
        ==============================================
        --> */}
      <Header />

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-one hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 m-auto">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-8">
                  <img
                    src={myImg}
                    alt="portfolio"
                    className="screen"
                  />
                </div>
                <div className="col-lg-6 col-md-4 justify-content-md-end justify-content-center">
                  <h2 className="page-title font-recoleta mt-50" >Edward Briscoe</h2>
                  <h4 className="font-recoleta mt-50 mb-50">I do code.</h4>
                  <h6 className="sub-heading">And I do it well.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.inside-hero-one --> */}

      {/* <!--
        =====================================================
       Case Study Section Three
        =====================================================
        --> */}

      <div className="case-study-three full-width mt-150 lg-mt-120">
        <div className="container-fluid">
          <HomeTabs />
        </div>
      </div>
    </div>
    // End .main-page-wrapper
  );
};

export default HomeContent;
