import React from "react";

const CopyrightSocial = () => {
  const socialContent = [
    {
      id: 1,
      iconName: "fa-envelope",
      socialLink: "mailto:eddieabriscoe@gmail.com",
    },
    {
      id: 2,
      iconName: "fa-github",
      socialLink: "https://github.com/helloitseddie",
    },
    {
      id: 3,
      iconName: "fa-linkedin-in",
      socialLink: "https://www.linkedin.com/in/edward-a-briscoe/",
    },
  ];

  return (
    <>
      {socialContent.map((social) => (
        <li key={social.id}>
          <a href={social.socialLink} target="_blank" rel="noopener noreferrer">
            <i className={social.id > 1 ? `fab ${social.iconName}`: `fa ${social.iconName}`}></i>
          </a>
        </li>
      ))}
    </>
  );
};

export default CopyrightSocial;
