import React from "react";
import CopyrightSocial from "../Footer/CopyrightSocial";
import MobileMenuContent from "../Footer/MobileMenuContent";

const SidebarFooter = () => {
  return (
    <>

      <MobileMenuContent className="mt-25"/>
      {/* End sidebar footer serach and address conent */}

      <ul className="d-flex social-icon style-none mt-50">
        <CopyrightSocial />
      </ul>
      {/* End d-flex */}
    </>
  );
};

export default SidebarFooter;
