import React from "react";

import ACEngineeringMain from "../../../assets/Web/ACEngineering/ACEngineeringMain.png";

const ACEngineeringContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>AC Engineering Website</h6>
              <h2 className="page-title font-recoleta">
                My <span>First</span> Try at Freelance Web Development
              </h2>
              <p>
                A robust, customizeable website for an AC Engineering company
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={ACEngineeringMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About AC Engineering</h3>
              <p>
              AC Engineering Inc. has over 40 years of continuous sales and design experience in South Florida involving critical application and solutions for commercial and residential buildings. 
              AC Engineering Inc. is committed to providing integrated solutions for mission critical applications involving precision cooling and power support for data centers and healthcare buildings, 
              dehumidification for indoor pool environments, UV lighting and indoor air quality solutions, and pre-fabricated custom solutions for industrial-commercial rated HVAC and electrical markets. 
              In addition, AC Engineering Inc. is able to support our customers during and after the construction process, including all aspects of system procurement, start-up, commissioning, monitoring, 
              and maintenance to ensure that these systems work as designed and adapt to the individual needs of the end user. AC Engineering Inc. has vast experience and expertise in the following product 
              lines, as well as many others, and can be counted on as your consultative partner for your next project.
              </p>
              <div className="border-block mb-50">
                <h6 className="sub-heading">Many, many iterations later</h6>
                <h3 className="title font-recoleta">
                  The Website
                </h3>
                <p>
                  For reference, the website is currently hosted on my github page <a href="https://helloitseddie.github.io" style={{color: "blue"}}>here</a>.
                </p>
                <p>
                  The goal was simple, make a sleek, modern website that can be edited and manipulated by someone without any web development experience. The website was commissioned by AC Engineering, an 
                  engineering firm in Miami, FL. The design specifications were very particular so I realized early on that it would be best to make it from scratch. The website also had to display current 
                  product lines/brands and also have a customizeable list of clients. Therefore, the website required a cdn that could be edited by someone with no technical training (if you've read a few of 
                  my product pages, I'm sure you can guess which cdn I decided to go with).
                </p>
                <h3 className="title font-recoleta mt-100">Frameworks</h3>
                <p>
                  The website was made from scratch in ReactJs. The customizeable brands/products/clients I mentioned above were delivered using the Contentful cdn. The Contact page was made possible with EmailJS 
                  to send emails straight from the website. 
                </p>
              </div>
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading mt-75">Result</h6>
              <h3 className="title font-recoleta">As It Stands Now</h3>
              <p>
                So far, the website is a fully comprehensive, fully reactive website customizeable to fit the needs of the client well after the final website is delivered. 
              </p>
              <p>
                Again, if you haven't visited the current state of the website, you may do so <a href="https://helloitseddie.github.io" style={{color: "blue"}}>here</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default ACEngineeringContent;