import React, { useEffect } from "react";
import AOS from "aos";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import ScrollToTop  from "./components/ScrollToTop";
import AllRoutes from "./router/AllRoutes";

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <Provider store={store}>
      <div className="h-100">
        <AllRoutes />
        <ScrollToTop />
        <ToastContainer />
      </div>
    </Provider>
  );
}

export default App;
