import React from "react";
import Resume from "../../assets/files/EABResume.pdf";

const MobileMenuContent = () => {

  return (
    <>

      <div className="address-block">
        <h4 className="title">Disclaimer</h4>
        <p>
          Projects are added/updated as they are completed. Make sure to check back often!
        </p>
        <p>
          Hiring? Download my resume below: <br />
          <a href={Resume} download>My Resume</a>
        </p>
      </div>
      {/* End .address-block */}
    </>
  );
};

export default MobileMenuContent;
