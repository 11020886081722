import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

import fiu from "../../../../assets/Home/School/FIU.jpeg";
import purdue from "../../../../assets/Home/School/Purdue.png";

const Education = () => {
  return (
    <div className="main-page-wrapper">
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="border-bottom col-xxl-9 col-xl-10 m-auto">
              
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading">2020 - 2023 (expected)</h6>
              <h3 className="title font-recoleta">Florida International University</h3>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={fiu}
                    thumbnail={fiu}
                    width="960"
                    height="300"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={fiu}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <p>
                Expected Graduation: May 2023 <br />
                Degree: Bachelors of Science <br />
                Major: Computer Science <br />
                GPA: 3.7 <br />
                Honors: Deans List <br />
                Scholarships: Florida SELECT STEM Tuition Grant <br />
                Associations: Upsilon Pi Epsilon Honors Society Member, Upsilon Pi Epsilon Code Committee Member, IEEE Computer Society,
                Association for Computing Machinery
              </p>
            </div>
            <div className="col-xxl-9 col-xl-10 m-auto">
              
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading mt-100">2017 - 2020</h6>
              <h3 className="title font-recoleta">Purdue University</h3>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={purdue}
                    thumbnail={purdue}
                    width="960"
                    height="300"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={purdue}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <p>
                Degree: Bachelors of Science <br />
                Major: Computer Engineering <br />
                Honors: Honors College Grantee <br />
                Scholarships: Presidential Scholarship, Purdue Hispanic Scholarship <br />
                Associations: Kappa Delta Rho Member, Minorities in Engineering Program Member, Latino Cultural Center Member
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}

    </div>
    // End .main-page-wrapper
  );
};

export default Education;
