import React from "react";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import HomeContent from "./helperPages/Home/HomeContent";
import Footer from "../components/Footer/Footer";

const Home = () => {
    return (
      <div className="main-page-wrapper light-bg">
        <Seo title="Home" />
        {/* End Seo Related data */}
  
        {/* <!-- 
        =============================================
        Sidebar Nav
        ============================================== 
          --> */}
        <Sidebar className="dark-style" />
        {/* End Sidebar */}
  
        {/* <!-- 
              =============================================
                  Theme Main Menu
              ============================================== 
              --> */}
        <Header />
        {/* End Header */}
              
        <HomeContent />

        <Footer />
      </div>
      // End .main-page-wrapper
    );
  };
  
  export default Home;
  