import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";

import csMain from "../../../assets/Web/CoralSafari/CoralSafariMain.png";
import howTo1 from "../../../assets/Web/CoralSafari/HowTo1.png";
import howTo2 from "../../../assets/Web/CoralSafari/HowTo2.png";
import Thx from "../../../assets/Web/CoralSafari/Thx.png";

const CoralSafariContent = () => {
  return (
    <div className="main-page-wrapper">

      {/* <!-- 
        =============================================
        Inside Page Banner
        ============================================== 
		--> */}
      <div className="inside-hero-four bg-color hero-spacing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-md-10 m-auto">
              <h6>The Coral Safari</h6>
              <h2 className="page-title font-recoleta">
                Saving our <span>reefs</span> one lionfish at a time
              </h2>
              <p>
                A website for geolocating invasive (and tasty) fish
              </p>
            </div>
          </div>
          {/* End .row */}

          <img
            src={csMain}
            alt="portfolio details banner"
            className="screen"
            width={"1169px"}
          />
        </div>
      </div>
      {/* <!-- /.inside-hero-four --> */}

      {/* <!--
        =====================================================
            Case Study Details
        =====================================================
        --> */}
      <div className="case-details-one">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 m-auto">
              <h6 className="sub-heading">overview</h6>
              <h3 className="title font-recoleta">About The Coral Safari</h3>
              <p>
                The Coral Safari was an idea I've had for a long time but finally decided to make it as my submission to the ShellHacks 2021 hackathon.
                The idea was relatively simple: make a geolocation tool to make it easier for people to find and harvest lionfish. Lionfish are an invasive 
                species of fish in southeastern United States that pose a huge threat to our native fish and Coral population. In highschool I was a 
                <a href="https://lionfishcentral.org/non-profit/reef-rangers-lionfish-news/" style={{color: "blue"}}>&nbsp;Reef Ranger</a> in charge of Reef Team 6 and
                I've been passionate about this issue for a long time.
              </p>
              <p>
                The eventual hope is to spark a national craving for Lionfish akin to what happened to the Redfish. One recipe drove that fish to near
                <a href="https://www.washingtonpost.com/archive/politics/1986/06/13/staving-off-a-black-day-for-redfish/70f2af59-d989-4fb0-b502-be516149c192/" style={{color: "blue"}}>&nbsp;extinction</a>.
                If you could harness that energy and unleash it on a species of fish that is damaging to local wildlife, then you might just be able to do something worthwhile.
                As a side note, I feel like I have some sort of authority on the subject, so please believe me when I say that Lionfish is the best tasting fish in the ocean
                bar none.
              </p>
              <p>
                Below is the demo/pitch video I made for the shellhacks submission. If you watch it, please keep in mind that I recorded it at 4:00am 
                at the end of a long weekend of coding. 
              </p>
              <div className="border-block mb-50"> 
                <h3 className="mb-25 font-recoleta">Demo Video:</h3>
                <iframe
                  style={{width: "100%", height: "500px", marginTop: 0}}
                  src="https://www.youtube.com/embed/rzP1FeetJXU" 
                  title="YouTube video player" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
                </iframe>
              </div>
              <div>
                <h6 className="sub-heading">A hectic weekend</h6>
                <h3 className="title font-recoleta">
                  The Design Process
                </h3>
                <p>
                  The Coral Safari was made in 36 hours for the ShellHacks 2021 Hackathon. For those who don't know, a hackathon is a timed coding competition
                  where people or teams compete to make software that tackle certain challenges or that follows a theme. I (foolishly) decided to work by myself 
                  because I signed up late and everyone already had ideas in mind. 
                </p>
                <p>
                  I started off knowing we needed a map and a recipe book. I made a very rough prototype with a map and a recipe book, then decided to add some filler
                  pages explaining how to catch lionfish, how to clean them, the locations of some popular dive spots, and a faq page. Then (the hardest part for me), 
                  I needed to make some art. I decided to change the name from my original idea "Lionhearted" because of domain name issues to "The Coral Safari". With that,
                  I made a logo, chose a font, and decided on the colors. By the time I reached the time limit, I had enough functionality and looks to make myself proud.
                </p>
                <h3 className="title font-recoleta mt-100">Frameworks</h3>
                <p>
                  Because of the time crunch, I had to use frameworks that I knew very well just to have any hope of accomplishing something useable. Contentful was used 
                  to store tags and their associated data, recipes, and popular fishing spots. In the future, tags and their data should definitely be stored on 
                  a database like firebase. The map and tag rendering was done using the Google Maps Api. Similarly, searching and autocompletion was done with 
                  the Google Places Api. Some rudimentary tag validation was done with the <a href="https://onwater.io/" style={{color: "blue"}}>OnWater.io Api</a>. 
                  This will eventually have to be changed because it's being deprecated :( <br /> The whole website was constructed with ReactJs and then hosted using 
                  Google Firebase. 
                </p>
              </div>
              {/* <!-- /.border-block --> */}
              <h6 className="sub-heading mt-75">Result</h6>
              <h3 className="title font-recoleta">As It Stands Now</h3>
              <p>
                The Coral Safari is no longer hosted online (I learned a very important lesson on hosting anonymously lest you want telemarketers calling you 24/7). The result 
                achieved at the end of the hackathon was enough for me to feel satisfied about the project, however I fully intend to pick this back up and develop into a useable 
                service. I also hope to partner with the FWC to bring it to the most amount of people possible. <span style={{textDecoration: "underline"}}>Mark my words, you have not heard the last of The Coral Safari!</span>
              </p>
              <p>
                Here's some pictures of The Coral Safari in action:
              </p>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={Thx}
                    thumbnail={Thx}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={Thx}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={howTo1}
                    thumbnail={howTo1}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={howTo1}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
              <div className="img-meta" data-aos="fade-up">
                <Gallery>
                  <Item
                    original={howTo2}
                    thumbnail={howTo2}
                    width="960"
                    height="510"
                  >
                    {({ ref, open }) => (
                      <img
                        className="cursor-pointer w-100"
                        ref={ref}
                        onClick={open}
                        src={howTo2}
                        alt="popup"
                        title="Click for large view"
                      />
                    )}
                  </Item>
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.case-details-one --> */}
    </div>
    // End .main-page-wrapper
  );
};

export default CoralSafariContent;