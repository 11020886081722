import React from "react";
import Seo from "../../../components/Seo";
import CSHeader from "../../../components/CSHeader";

import CSCookingContent from "../../helperPages/iOS/CoralSafari/CookingContent";

const CSCooking = () => {
    return (
      <div className="main-page-wrapper light-bg">
        <Seo title="How to catch a lionfish" />

        {/* <!-- 
              =============================================
                  Theme Main Menu
              ============================================== 
              --> */}
        <CSHeader />
        {/* End Header */}
        <CSCookingContent />
      </div>
      // End .main-page-wrapper
    );
  };
  
  export default CSCooking;
  