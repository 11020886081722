import React from "react";
import Seo from "../../../components/Seo";
import CSHeader from "../../../components/CSHeader";

import CSCleaningContent from "../../helperPages/iOS/CoralSafari/CleaningContent";

const CSCleaning = () => {
    return (
      <div className="main-page-wrapper light-bg">
        <Seo title="How to catch a lionfish" />

        {/* <!-- 
              =============================================
                  Theme Main Menu
              ============================================== 
              --> */}
        <CSHeader />
        {/* End Header */}
        <CSCleaningContent />
      </div>
      // End .main-page-wrapper
    );
  };
  
  export default CSCleaning;
  